import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GerinusService } from '../lib/gerinus.service';

@Injectable({
  providedIn: 'root'
})
export class FilingNoteService extends GerinusService {

  constructor(public http: HttpClient) { 
    super(http)
  }

  requestArchivament(id: number, personId: number): Observable<any> {
    const url = this.apiURL() + "/api/note/request-archivament/" + id + "/" + personId;
    console.log("[requestArchivament] url: ", url)
    return this.http.post(url, {} , this.headers())
  }

  acceptOrRefuseArchivament(id: number, status: number, aprovadorId: number): Observable<any>  {
    const url = this.apiURL() + "/api/note/confirm-request-archivament/" + id + "/" + status + "/" + aprovadorId;
    return this.http.post(url, {} , this.headers())
  }
}
