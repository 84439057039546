import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    IAddCertificateSchema,
    IAddComplementaryFilesSchema,
    IAddDispachSchema,
    IAddDispachReport,
    IProccess,
    ITransmitSchema,
    IAddResponsibleSchema,
    IJoinOnProcessSchema,
    IMarkProcess,
    IProcessUserFeedbackSchema,
    ISubmitExtendProcessSchema,
    ISendRequesterConsiderationsSchema,
    ICancellationEvaluationSchema,
    IRequestToCancelProcessSchema,
    IToggleResponsibleSchema,
    IAcceptBeingResponsibleRequestBodySchema
} from 'src/app/interfaces/proccess.interface';

import { GerinusService } from '../lib/gerinus.service';

import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as QRCode from 'qrcode';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { interRegular } from '../../../assets/fonts/inter/Inter-Regular-normal';
import { interSemiBold } from '../../../assets/fonts/inter/Inter-SemiBold-normal';

import { environment } from 'src/environments/environment';
import { UploadService } from './upload.service';
import { GlobalService } from '../lib/global.service';
import { processGeneratesDocumentsName } from 'src/app/Enums/commonProcessEnum';
import { ISendFileProcessPdf } from 'src/app/interfaces/processCommon.interface';

@Injectable()
export class ProcessService extends GerinusService {
    constructor(
        public http: HttpClient,
        private datePipe: DatePipe,
        private uploadService: UploadService,
        private global: GlobalService
    ) {
        super(http);
        this.entityName = 'processo';
        this.idField = 'PRC_CodigoProcesso';
        this.model = 'ProcessoModel';
    }

    public orderNumber: number;

    public async generateProcessPDF(process): Promise<ISendFileProcessPdf[]>  {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const urlPublicProcess = environment.projectURL + '/free/processo/' + process.PRC_NumeroProcesso;

        let qrcode: string;

        QRCode.toDataURL(urlPublicProcess, (_, url) => {
            var base64Data = url.split(',')[1];
            qrcode = atob(base64Data);
        });

        const renderHeader = () => {
            const logoProtocolize = new Image();
            logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
            doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

            doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
            doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
            doc.setFont('Inter-SemiBold');

            doc.addFileToVFS('Inter-Regular.ttf', interRegular);
            doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text('Sistema de Protocolo e Tramitação Eletrônica', pageWidth / 2, 30, {
                align: 'center',
            });
        };

        const renderFooter = () => {
            autoTable(doc, {
                body: [
                    [
                        {
                            content: `Situação em ${this.datePipe.transform(new Date(), "dd/MM/yyyy 'às' HH:mm:ss")}`,
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 70,
                                fontSize: 10,
                                cellPadding: {
                                    bottom: 0,
                                    top: 0,
                                    right: 0,
                                    left: 3,
                                },
                                textColor: '#0A2156',
                            },
                        },
                        {
                            content: this.getStatusDescription(process.Status),
                            styles: {
                                halign: 'left',
                                fontSize: 10,
                                cellWidth: 85,
                                cellPadding: 3,
                                textColor: '#687489',
                            },
                        },
                        {
                            rowSpan: 3,
                            styles: {
                                halign: 'center',
                                valign: 'middle',
                                fontSize: 10,
                                textColor: '#687489',
                            },
                        },
                    ],
                    [
                        {
                            content: 'Unidade Atual',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 70,
                                fontSize: 10,
                                cellPadding: 3,
                                textColor: '#0A2156',
                            },
                        },
                        {
                            content: process.OR_Sigla + ' - ' + process.nomeDepartamentoAtual,
                            styles: {
                                halign: 'left',
                                fontSize: 10,
                                cellPadding: 3,
                                textColor: '#687489',
                            },
                        },
                    ],
                    [
                        {
                            content: 'Chave de verificação',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 70,
                                fontSize: 10,
                                cellPadding: 3,
                                textColor: '#0A2156',
                            },
                        },
                        {
                            content: process.PRC_ChaveAutenticidade,
                            styles: {
                                halign: 'left',
                                fontSize: 10,
                                cellPadding: 3,
                                textColor: '#687489',
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 235,
                styles: {
                    lineWidth: 0.2,
                    lineColor: '#687489',
                    font: 'Inter-Regular',
                },
            });

            doc.addImage(qrcode, 'PNG', 171, 239, 23, 22);

            const logoSga = new Image();
            logoSga.src = '../../assets/img/brand/brasao-sga.png';
            doc.addImage(logoSga, 'png', 73, 270, 20, 18);

            const line = new Image();
            line.src = '../../assets/img/brand/line.png';
            doc.addImage(line, 'png', 100, 275, 0.2, 10);

            doc.setFont('Inter-Regular');
            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text(process.OR_Descricao, 107, 279, { maxWidth: 40 });
        };

        renderHeader();
        renderFooter();

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(`NUP: ${process.PRC_NumeroProcesso}`, pageWidth / 2, 40, {
            align: 'center',
        });

        const dataProcesso = this.datePipe.transform(process.PRC_DataRecebimento, "dd/MM/yyyy 'às' HH:mm");

        doc.setFontSize(12);
        doc.setFont('Inter-Regular');
        doc.setTextColor('#687489');
        doc.text('Recebido em: ' + dataProcesso, pageWidth / 2, 48, {
            align: 'center',
        });

        const addNewPage = (title: string) => {
            doc.addPage();

            doc.setFontSize(24);
            doc.setFont('Inter-SemiBold');
            doc.setTextColor('#0A2156');
            doc.text(title, pageWidth / 2, 45, { align: 'center' });

            renderHeader();
            renderFooter();
        };

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Solicitante:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                ],
                [
                    {
                        content: process.SOLR_NomeRazaoSocial
                            ? `${this.formatCpfCnpj(process.SOLR_CpfCnpj)} - ${process.SOLR_NomeRazaoSocial}`
                            : `${this.formatCpfCnpj(process.PE_CPF)} - ${process.PE_Nome}`,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 56,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Departamento Atual:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'auto',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                    {
                        content: 'Assunto:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'auto',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
                [
                    {
                        content: process.OR_Sigla + ' - ' + process.nomeDepartamentoAtual,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                    {
                        content: process.AS_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 72,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text('Descrição da solicitação', 15, 100);

        doc.setFontSize(12);
        doc.setFont('Helvetica');
        doc.setTextColor('#687489');
        doc.text(process.SOL_Descricao, 15, 110, {
            align: 'justify',
            maxWidth: 180,
        });

        if (process.despachos.length > 0) {
            addNewPage('Folha de Despacho');

            const despachoData = process.despachos.map((despacho) => [
                dayjs(despacho.created_at).format('DD/MM/YYYY'),
                despacho.PDES_Descricao,
            ]);

            const formattedData = despachoData.map(row =>
                row.map(cell => cell
                    .replace(/\t/g, '    ')
                )
            );

            autoTable(doc, {
                head: [['Data', 'Despacho']],
                body: formattedData,
                theme: 'striped',
                startY: 65,
                styles: {
                    font: 'Inter-Regular',
                    minCellHeight: 8,
                    valign: 'middle',
                    halign: 'left',
                },
                alternateRowStyles: {
                    fillColor: '#F2F5F9',
                },
                headStyles: {
                    fillColor: '#417bff',
                    font: 'Inter-SemiBold',
                    minCellHeight: 8,
                    valign: 'middle',
                    halign: 'left',
                },
            });
        }


        addNewPage('Folha de Registros');

        const historicosData = process.historicos.map((historico) => [
            this.datePipe.transform(historico.PRCH_Data, "dd/MM/yyyy 'às' HH:mm:ss"),
            historico.PRCH_Historico,
        ]);

        autoTable(doc, {
            head: [['Data', 'Histórico']],
            body: historicosData,
            theme: 'striped',
            startY: 65,
            styles: {
                font: 'Inter-Regular',
                minCellHeight: 8,
                valign: 'middle',
                halign: 'left',
            },
            alternateRowStyles: {
                fillColor: '#F2F5F9',
            },
            headStyles: {
                fillColor: '#417bff',
                font: 'Inter-SemiBold',
                minCellHeight: 8,
                valign: 'middle',
                halign: 'left',
            },
        });

        const listBlobPdf = [
            { name: processGeneratesDocumentsName.PROCESS, file: doc.output('blob') }, 
            { name: processGeneratesDocumentsName.DOCUMENT_OF_PROCESS, file: this.generatePageDocumentCover(process, "DOCUMENTOS DO PROCESSO", "#0A2156", qrcode)},
            { name: processGeneratesDocumentsName.ADDITIONAL_DOCUMENTS, file: this.generatePageDocumentCover(process, "DOCUMENTOS COMPLEMENTARES", "#0A2156", qrcode, 28)},
            { name: processGeneratesDocumentsName.DISPATCH_DOCUMENTO, file: this.generatePageDocumentCover(process, "PARECER DE DESPACHO", "#0A2156", qrcode)},
            { name: processGeneratesDocumentsName.DOCUMENT_CANCELED, file: this.generatePageDocumentCover(process, "DOCUMENTOS CANCELADOS", "#f5365c", qrcode)}
        ]

        return listBlobPdf;
    }

    public generatePageDocumentCover(process, text: string, color: string,  qrcode, fontSize = 36): Blob {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        const logoProtocolize = new Image();
        logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
        doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

        doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
        doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
        doc.setFont('Inter-SemiBold');

        doc.addFileToVFS('Inter-Regular.ttf', interRegular);
        doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text('Sistema de Protocolo e Tramitação Eletrônica', pageWidth / 2, 30, {
            align: 'center',
        });

        doc.setFontSize(fontSize);
        doc.setFont('Inter-SemiBold');
        doc.setTextColor(color);
        doc.text(text, pageWidth / 2, 140, { align: 'center' });

        autoTable(doc, {
            body: [
                [
                    {
                        content: `Situação em ${this.datePipe.transform(new Date(), "dd/MM/yyyy 'às' HH:mm:ss")}`,
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 3,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: this.getStatusDescription(process.Status),
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellWidth: 85,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                    {
                        rowSpan: 3,
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            fontSize: 10,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content: 'Unidade Atual',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: 3,
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: process.OR_Sigla + '-' + process.nomeDepartamentoAtual,
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 240,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        doc.addImage(qrcode, 'PNG', 171, 241, 23, 22);

        const logoSga = new Image();
        logoSga.src = '../../assets/img/brand/brasao-sga.png';
        doc.addImage(logoSga, 'png', 73, 270, 20, 18);

        const line = new Image();
        line.src = '../../assets/img/brand/line.png';
        doc.addImage(line, 'png', 100, 275, 0.2, 10);

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(process.OR_Descricao, 107, 279, { maxWidth: 40 });

        return doc.output('blob');
    }

    public generateComplementaryDocumentsCover(process, qrcode): ArrayBuffer {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        const logoProtocolize = new Image();
        logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
        doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

        doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
        doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
        doc.setFont('Inter-SemiBold');

        doc.addFileToVFS('Inter-Regular.ttf', interRegular);
        doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text('Sistema de Protocolo e Tramitação Eletrônica', pageWidth / 2, 30, {
            align: 'center',
        });

        doc.setFontSize(36);
        doc.setFont('Inter-SemiBold');
        doc.setTextColor('#0A2156');
        doc.text('DOCUMENTOS', pageWidth / 2, 120, { align: 'center' });
        doc.text('COMPLEMENTARES', pageWidth / 2, 140, { align: 'center' });

        autoTable(doc, {
            body: [
                [
                    {
                        content: `Situação em ${this.datePipe.transform(new Date(), "dd/MM/yyyy 'às' HH:mm:ss")}`,
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 3,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: this.getStatusDescription(process.Status),
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellWidth: 85,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                    {
                        rowSpan: 3,
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            fontSize: 10,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content: 'Unidade Atual',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: 3,
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: process.OR_Sigla + '-' + process.nomeDepartamentoAtual,
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 240,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        doc.addImage(qrcode, 'PNG', 171, 241, 23, 22);

        const logoSga = new Image();
        logoSga.src = '../../assets/img/brand/brasao-sga.png';
        doc.addImage(logoSga, 'png', 73, 270, 20, 18);

        const line = new Image();
        line.src = '../../assets/img/brand/line.png';
        doc.addImage(line, 'png', 100, 275, 0.2, 10);

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(process.OR_Descricao, 107, 279, { maxWidth: 40 });

        return doc.output('arraybuffer');
    }

    getStatusDescription(processStatus: number): string {
        const statusDescriptions = {
            1: 'Novo',
            2: 'Em Andamento',
            3: 'Arquivado',
            4: 'Concluído',
            5: 'Em Correção',
            6: 'Vencido',
            7: 'Anulado',
            8: 'Respondido'
        };

        return statusDescriptions[processStatus];
    }

    getStatusResponsibleDescription(processStatus: number): string {
        const statusDescriptions = {
            0: 'Aceite Pendente',
            1: 'Aceito',
            2: 'Recusado'
        };

        return statusDescriptions[processStatus];
    }

    publicProcess(id: string) {
        return this.http.get(this.apiURL() + '/api/free/process/' + id);
    }

    async conclusionProccess(processo: IProccess) {
        const doc = new jsPDF('p', 'mm', 'a4');

        const addNewPage = (title: string) => {
            doc.addPage();

            doc.setFontSize(24);
            doc.setFont('Inter-SemiBold');
            doc.setTextColor('#0A2156');
            doc.text(title, pageWidth / 2, 45, { align: 'center' });

            renderHeader();
            renderFooter();
        };

        const renderHeader = () => {
            const logoProtocolize = new Image();
            logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
            doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

            doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
            doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
            doc.setFont('Inter-SemiBold');

            doc.addFileToVFS('Inter-Regular.ttf', interRegular);
            doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
        };

        const renderFooter = () => {
            const logoSga = new Image();
            logoSga.src = '../../assets/img/brand/logo_sga_horizontal.png';
            doc.addImage(logoSga, 'png', 50, 270, 45, 20);

            const line = new Image();
            line.src = '../../assets/img/brand/line.png';
            doc.addImage(line, 'png', 100, 275, 0.2, 10);

            doc.setFont('Inter-Regular');
            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text(processo.OR_Descricao, 107, 279, { maxWidth: 40 });
        };

        renderHeader();

        const historicosData = processo.historicos.map((historico) => [
            this.datePipe.transform(historico.PRCH_Data, "dd/MM/yyyy 'às' HH:mm:ss"),
            historico.PRCH_Historico,
        ]);

        autoTable(doc, {
            head: [['Data', 'Histórico']],
            body: historicosData,
            theme: 'striped',
            startY: 65,
            styles: {
                font: 'Inter-Regular',
                minCellHeight: 12,
                valign: 'middle',
                halign: 'left',
            },
            alternateRowStyles: {
                fillColor: '#F2F5F9',
            },
            headStyles: {
                fillColor: '#417bff',
                font: 'Inter-SemiBold',
                minCellHeight: 12,
                valign: 'middle',
                halign: 'left',
            },
        });

        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        await addNewPage('Folha de Despacho');

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Despacho',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            fontSize: 10,
                            cellPadding: 4,
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 100,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        return doc.save(`Processo N° ${processo.PRC_NumeroProcesso}.pdf`);
    }

    transmit(processId: number, data: ITransmitSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/transmit', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addDispatch(processId: number, data: IAddDispachSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-dispatch', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addDispatchReport(processId: number, data: IAddDispachReport): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-dispatch-report', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addComplementaryFiles(processId: number, data: IAddComplementaryFilesSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-complementary-files', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addCertificate(processId: number, data: IAddCertificateSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-certificate', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    sendRequesterCorrection(processId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/send-requester-correction', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    sendToCorrection(processId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/send-to-correction', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    markProcessAsCompleted(processId: number, data: IMarkProcess): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/mark-as-completed', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    markProcessAsArchived(processId: number, data: IMarkProcess): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/mark-as-archived', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addResponsible(processId: number, data: IAddResponsibleSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-responsible', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    toggleResponsible(processId: number, data: IToggleResponsibleSchema): Observable<any> {
        return this.http.put(this.apiURL() + '/api/process/' + processId + '/toggle-responsible', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    join(processId: number, data: IJoinOnProcessSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/join', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    search(data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/free/process/search', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    getTransmissions(processId: number): Observable<any> {
        return this.http.get(this.apiURL() + '/api/free/process/' + processId + '/get-transmissions', this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    submitUserFeedback(data: IProcessUserFeedbackSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/free/process/add-user-feedback', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    listProcessFeedback(processId: number): Observable<any> {
        return this.http.get(this.apiURL() + `/api/free/process/${processId}/list-user-feedbacks/`, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    submitExtendsProcess(data: ISubmitExtendProcessSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/extend-process', data, this.headers())
    }

    updateStatusToEvaluation(processId: number) {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/update-to-evaluation', null, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    saveRequesterConsiderations(processId: number, data: ISendRequesterConsiderationsSchema) {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-requester-considerations', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addRequestToCancel(processId: number, data: IRequestToCancelProcessSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/add-request-to-cancel', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    confirmCancellationProcess(processId: number, data: ICancellationEvaluationSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/confirm-cancellation', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    refuseCancellationProcess(processId: number, data: ICancellationEvaluationSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/refuse-cancellation', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    getResponsibleRequests(processId: number) {
        return this.http.get(this.apiURL() + '/api/process/' + processId + '/get-responsible-requests', this.headers());
    }

    sendAcceptBeingResponsible(processId: number, data: IAcceptBeingResponsibleRequestBodySchema) {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/accept-being-responsible', { data }, this.headers());
    }
    
    cancelSeemDispatch(processId: number, data: any) {
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/cancel-seem-dispatch', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    getAllCorrections(processId: number) {
        return this.http.get(this.apiURL() + '/api/process/' + processId + '/get-corrections', this.headers())
    }
    
    cancelComplementaryFiles(processId: number, data: any) {
        return this.http.put(this.apiURL() + '/api/process/' + processId + '/cancel-complementary-files', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    unarchivingProcessAutomaticArchived(processId: number) {
        return this.http.put(this.apiURL() + '/api/process/' + processId + '/unarchiving-process-automatic-archived', {}, this.headers())
    }
     
    reopenProcess(processId: number) {
        return this.http.get(this.apiURL() + '/api/process/' + processId + '/reopen-process', this.headers());
    }
    
    associarDam(processId: number, damId: string): Observable<any> {
        const data = { damId };
        return this.http.post(this.apiURL() + '/api/process/' + processId + '/associar-dam', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    removeDam(processId: number, damId: number): Observable<any> {
        return this.http.delete(this.apiURL() + '/api/process/' + processId + '/remove-dam/'+damId, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }

    public mergedDocumentInPdf(files: ISendFileProcessPdf[], processId: number, processNumber: string, org: string) {
        const formData = new FormData()
        for (const fileElement of files) {
            formData.append(fileElement.name + ".pdf", fileElement.file)
        }   
        return this.http.post(this.apiURL() + '/api/pdf/process/' + processId + '/' + processNumber + "/" + org, formData , { ...this.headers(true), responseType: 'blob'}); 
    } 

}
