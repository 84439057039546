import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { forkJoin, BehaviorSubject, Observable, throwError } from 'rxjs';
import jsPDF from 'jspdf';
import { environment } from 'src/environments/environment';
import * as QRCode from 'qrcode';
import { interRegular } from '../../../assets/fonts/inter/Inter-Regular-normal';
import { interSemiBold } from '../../../assets/fonts/inter/Inter-SemiBold-normal';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { UploadService } from './upload.service';
import { internalProcessGeneratesDocumentsName, internalProcessStatusEnum, internalProcessStatusText } from 'src/app/Enums/internalProcessEnum';
import { ISendFileProcessPdf } from 'src/app/interfaces/processCommon.interface';
import { processGeneratesDocumentsName } from 'src/app/Enums/commonProcessEnum';
import { IAddDispachInternalProcessSchema, IChangeInternalProcessDescriptionSchema, IChangeInternalProcessSubjectSchema, ICorrectionDataInternalProcessSchema, IInternalProcessUserFeedbackSchema, ISendInternalProcessConsiderationsSchema, ISubmitExtendInternalProcessSchema, ITransmitInternalProcessSchema } from 'src/app/interfaces/internalProcess.interfaces';

@Injectable({
    providedIn: 'root',
})
export class InternalProcessService extends GerinusService {

    private filterSearch = new BehaviorSubject<any>(null);

    constructor(
        public http: HttpClient,
        private datePipe: DatePipe,
        private uploadService: UploadService,
    ) {
        super(http);
        this.entityName = 'processo_interno';
        this.idField = 'PI_CodigoProcessoInterno';
        this.model = 'ProcessoInternoModel';
    }

    setValueFilterSubject(value: any) {
        this.filterSearch.next(value);
    }

    getValueSubject() {
        return this.filterSearch.value;
    }

    getSearchSubject() {
        return this.filterSearch;
    }

    getFilterSubjectObservable(): Observable<any> {
        return this.filterSearch.asObservable();
    }

    listProcessFeedback(internalProcessId: number): Observable<any> {
        return this.http.get(this.apiURL() + `/api/free/internal-process/${internalProcessId}/list-user-feedbacks/`, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    submitUserFeedback(data: IInternalProcessUserFeedbackSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/free/internal-process/add-user-feedback', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    updateStatusToEvaluation(internalProcessId: number) {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/update-to-evaluation', null, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    sendToCorrection(internalProcessId: number, data: ICorrectionDataInternalProcessSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/send-to-correction', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    sendRequesterCorrection(internalProcessId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/send-requester-correction', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    saveRequesterConsiderations(internalProcessId: number, data: ISendInternalProcessConsiderationsSchema) {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/add-requester-considerations', data, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addResponsible(internalProcessId: number, data): Observable<any> {
        return this.http
            .post(
                this.apiURL() + '/api/internal-process/' + internalProcessId + '/add-responsible',
                data,
                this.headers(),
            )
            .pipe(catchError(this.handleError));
    }

    toggleResponsible(internalProcessId: number, data): Observable<any> {
        return this.http
            .put(
                this.apiURL() + '/api/internal-process/' + internalProcessId + '/toggle-responsible',
                data,
                this.headers(),
            )
            .pipe(catchError(this.handleError));
    }

    transmit(internalProcessId: number, data: ITransmitInternalProcessSchema): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/transmit', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    join(internalProcessId: number, data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/join', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    search(data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/free/internal-process/search-internal-process', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    getTransmissions(internalProcessId: number): Observable<any> {
        return this.http.get(this.apiURL() + '/api/free/internal-process/' + internalProcessId + '/get-internal-process-transmissions', this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    markProcessAsCompleted(internalProcessId: number, data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/mark-as-completed', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    markProcessAsArchived(internalProcessId: number, data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/mark-as-archived', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    markProcessAsUnarchived(internalProcessId: number, data: any): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/mark-as-unarchived', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    addDispatch(internalProcessId: number, data: IAddDispachInternalProcessSchema): Observable<any> {
        return this.http
            .post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/add-dispatch', data, this.headers())
            .pipe(catchError(this.handleError));
    }

    submitExtendsInternalProcess(data: ISubmitExtendInternalProcessSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/extend-internal-process', data, this.headers())
    }

    confirmCancellationInternalProcess(internalProcessId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/confirm-cancellation', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    addRequestToCancel(internalProcessId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/add-request-to-cancel', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    refuseCancellationInternalProcess(internalProcessId: number, data: any): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/refuse-cancellation', { data }, this.headers())
            .pipe(
                catchError(this.handleError)
            )
    }

    statusChange(internalProcessId: number, data): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/status-change', data, this.headers())
                        .pipe(catchError(this.handleError));
    }

    updateProcessInternalDescription(data: IChangeInternalProcessDescriptionSchema, internalProcessId): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/edit-description', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    updateProcessInternalSubject(data: IChangeInternalProcessSubjectSchema, internalProcessId): Observable<any> {
        return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/edit-subject', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }

    public async generateProcessPDF(process): Promise<ISendFileProcessPdf[]> {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const urlPublicProcess = environment.projectURL + '/free/processo/' + process.PRC_NumeroProcesso;
        let qrcode: string;

        QRCode.toDataURL(urlPublicProcess, (_, url) => {
            var base64Data = url.split(',')[1];
            qrcode = atob(base64Data);
        });

        const addNewPage = (title: string) => {
            doc.addPage();

            doc.setFontSize(24);
            doc.setFont('Inter-SemiBold');
            doc.setTextColor('#0A2156');
            doc.text(title, pageWidth / 2, 45, { align: 'center' });

            renderHeader();
            renderFooter();
        };

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(`NPA: ${process.PI_NumeroProcessoInterno}`, pageWidth / 2, 40, {
            align: 'center',
        });

        const renderHeader = () => {
            const logoProtocolize = new Image();
            logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
            doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

            doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
            doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
            doc.setFont('Inter-SemiBold');

            doc.addFileToVFS('Inter-Regular.ttf', interRegular);
            doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text('Sistema de Protocolo e Tramitação Eletrônica', pageWidth / 2, 30, {
                align: 'center',
            });
        };

        const renderRequest = () => {
            autoTable(doc, {
                body: [
                    [
                        {
                            content: 'Solicitante:',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 'wrap',
                                fontSize: 10,
                                textColor: '#0a2156',
                                cellPadding: { left: 4, top: 2, right: 4 },
                            },
                        },
                    ],
                    [
                        {
                            content: this.formatCpfCnpj(process.pessoaCriadoraCPF) + ' - ' + process.pessoaCriadoraNome,
                            styles: {
                                halign: 'left',
                                fontSize: 12,
                                textColor: '#687489',
                                cellPadding: {
                                    left: 4,
                                    top: 2,
                                    right: 4,
                                    bottom: 2,
                                },
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 56,
                styles: {
                    fillColor: '#f2f5f9',
                },
            });
            autoTable(doc, {
                body: [
                    [
                        {
                            content: 'Departamento atual:',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 'auto',
                                fontSize: 10,
                                textColor: '#0a2156',
                                cellPadding: { left: 4, top: 2, right: 4 },
                            },
                        },
                        {
                            content: 'Assunto:',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 'auto',
                                fontSize: 10,
                                textColor: '#0a2156',
                                cellPadding: { left: 4, top: 2, right: 4 },
                                lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                                lineColor: '#ffffff',
                            },
                        },
                    ],
                    [
                        {
                            content: process.depDestinatarioDescricao,
                            styles: {
                                halign: 'left',
                                fontSize: 12,
                                textColor: '#687489',
                                cellPadding: {
                                    left: 4,
                                    top: 2,
                                    right: 4,
                                    bottom: 2,
                                },
                            },
                        },
                        {
                            content: process.PI_Assunto,
                            styles: {
                                halign: 'left',
                                fontSize: 12,
                                textColor: '#687489',
                                cellPadding: {
                                    left: 4,
                                    top: 2,
                                    right: 4,
                                    bottom: 2,
                                },
                                lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                                lineColor: '#ffffff',
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 72,
                styles: {
                    fillColor: '#f2f5f9',
                },
            });
        };

        const renderFooter = () => {
            autoTable(doc, {
                body: [
                    [
                        {
                            content: (process.PI_Retroativo) ? `Situação Atual` : `Situação em ${this.datePipe.transform(new Date(), "dd/MM/yyyy 'às' HH:mm:ss")}`,
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 70,
                                fontSize: 10,
                                cellPadding: {
                                    bottom: 0,
                                    top: 0,
                                    right: 0,
                                    left: 3,
                                },
                                textColor: '#0A2156',
                            },
                        },
                        {
                            content: this.correctStatus(process.PIT_Status).text,
                            styles: {
                                halign: 'left',
                                fontSize: 10,
                                cellWidth: 85,
                                cellPadding: 4,
                                textColor: '#687489',
                            },
                        },
                        {
                            rowSpan: 3,
                            styles: {
                                halign: 'center',
                                valign: 'middle',
                                fontSize: 10,
                                textColor: '#687489',
                            },
                        },
                    ],
                    [
                        {
                            content: 'Unidade Atual',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 70,
                                fontSize: 10,
                                cellPadding: 4,
                                textColor: '#0A2156',
                            },
                        },
                        {
                            content: process.orgDestinatarioSigla + '-' + process.orgDestinatarioDescricao,
                            styles: {
                                halign: 'left',
                                fontSize: 10,
                                cellPadding: 4,
                                textColor: '#687489',
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 240,
                styles: {
                    lineWidth: 0.2,
                    lineColor: '#687489',
                    font: 'Inter-Regular',
                },
            });

            doc.addImage(qrcode, 'PNG', 171, 241, 23, 22);

            const logoSga = new Image();
            logoSga.src = '../../assets/img/brand/brasao-sga.png';
            doc.addImage(logoSga, 'png', 73, 270, 20, 18);

            const line = new Image();
            line.src = '../../assets/img/brand/line.png';
            doc.addImage(line, 'png', 100, 275, 0.2, 10);

            doc.setFont('Inter-Regular');
            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text(process.orgTramitadorDescricao, 107, 279, { maxWidth: 40 });
        };

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text('Detalhamento da solicitação:', 15, 100);

        doc.setFontSize(12);
        doc.setFont('Helvetica');
        doc.setTextColor('#687489');
        doc.text(process.PI_Observacao, 15, 110, {
            align: 'justify',
            maxWidth: 180,
        });

        const renderHistory = async () => {
            addNewPage('Folha de Registros');

            const historicosData = process.history.map((historico) => {
                return (process.PI_Retroativo) ? [historico.PIH_Historico] : [
                    this.datePipe.transform(historico.created_at, "dd/MM/yyyy 'às' HH:mm:ss"),
                    historico.PIH_Historico,
                ]
            });

            autoTable(doc, {
                head: [(process.PI_Retroativo) ? ['Histórico'] : ['Data', 'Histórico']],
                body: historicosData,
                theme: 'striped',
                startY: 65,
                styles: {
                    font: 'Inter-Regular',
                    minCellHeight: 8,
                    valign: 'middle',
                    halign: 'left',
                },
                alternateRowStyles: {
                    fillColor: '#F2F5F9',
                },
                headStyles: {
                    fillColor: '#417bff',
                    font: 'Inter-SemiBold',
                    minCellHeight: 8,
                    valign: 'middle',
                    halign: 'left',
                },
            });
        };
        renderHeader();
        renderRequest();
        renderFooter();
        renderHistory();

        const listBlobPdf = [
            { name: processGeneratesDocumentsName.PROCESS, file: doc.output('blob') }, 
            { name: processGeneratesDocumentsName.DOCUMENT_OF_PROCESS, file: this.generatePageDocumentsCover(process, "DOCUMENTOS DO PROCESSO", "#0A2156", qrcode)},
            { name: processGeneratesDocumentsName.ADDITIONAL_DOCUMENTS, file: this.generatePageDocumentsCover(process, "DOCUMENTOS COMPLEMENTARES", "#0A2156", qrcode, 28)},
            { name: processGeneratesDocumentsName.DISPATCH_DOCUMENTO, file: this.generatePageDocumentsCover(process, "PARECER DE DESPACHO", "#0A2156", qrcode)},
            { name: processGeneratesDocumentsName.DOCUMENT_CANCELED, file: this.generatePageDocumentsCover(process, "DOCUMENTOS CANCELADOS", "#f5365c", qrcode)}
        ]

        return listBlobPdf;
    }

    public generatePageDocumentsCover(process, text: string, color: string, qrcode, fontSize = 36): Blob {
        const doc = new jsPDF('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        const logoProtocolize = new Image();
        logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
        doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

        doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
        doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
        doc.setFont('Inter-SemiBold');

        doc.addFileToVFS('Inter-Regular.ttf', interRegular);
        doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text('Sistema de Protocolo e Tramitação Eletrônica', pageWidth / 2, 30, {
            align: 'center',
        });

        doc.setFontSize(fontSize);
        doc.setFont('Inter-SemiBold');
        doc.setTextColor(color);
        doc.text(text, pageWidth / 2, 140, { align: 'center' });

        autoTable(doc, {
            body: [
                [
                    {
                        content: (process.PI_Retroativo) ? `Situação Atual` : `Situação em ${this.datePipe.transform(new Date(), "dd/MM/yyyy 'às' HH:mm:ss")}`,
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 3,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: this.correctStatus(process.PIT_Status).text,
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellWidth: 85,
                            cellPadding: 4,
                            textColor: '#687489',
                        },
                    },
                    {
                        rowSpan: 3,
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            fontSize: 10,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content: 'Unidade Atual',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 70,
                            fontSize: 10,
                            cellPadding: 4,
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: process.orgDestinatarioSigla + '-' + process.orgDestinatarioDescricao,
                        styles: {
                            halign: 'left',
                            fontSize: 10,
                            cellPadding: 4,
                            textColor: '#687489',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 240,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        doc.addImage(qrcode, 'PNG', 171, 241, 23, 22);

        const logoSga = new Image();
        logoSga.src = '../../assets/img/brand/brasao-sga.png';
        doc.addImage(logoSga, 'png', 73, 270, 20, 18);

        const line = new Image();
        line.src = '../../assets/img/brand/line.png';
        doc.addImage(line, 'png', 100, 275, 0.2, 10);

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(process.orgTramitadorDescricao, 107, 279, { maxWidth: 40 });

        return doc.output('blob');
    }

    public formatCpfCnpj(cpfCnpj: string) {
        const isCPF = cpfCnpj.length === 11;

        return cpfCnpj.replace(
            isCPF ? /^(\d{3})(\d{3})(\d{3})(\d{2})$/ : /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
            isCPF ? '$1.$2.$3-$4' : '$1.$2.$3/$4-$5',
        );
    }

    public correctStatus(status: number): { text: string, class: string, color: string } {
        switch (status) {
            case internalProcessStatusEnum.NOVO:
                return {
                    text: internalProcessStatusText.NOVO,
                    class: "badge-primary",
                    color: "bg-primary text-white"
                }
            case internalProcessStatusEnum.EM_ANDAMENTO:
                return {
                    text: internalProcessStatusText.EM_ANDAMENTO,
                    class: "badge-info",
                    color: "bg-info text-white"
                }
            case internalProcessStatusEnum.ARQUIVADO:
                return {
                    text: internalProcessStatusText.ARQUIVADO,
                    class: "badge-secondary text-muted",
                    color: "badge-secondary bg-dark text-white"
                };
            case internalProcessStatusEnum.CONCLUIDO:
                return {
                    text: internalProcessStatusText.CONCLUIDO,
                    class: "badge-success",
                    color: "bg-success text-white"
                };
            case internalProcessStatusEnum.TRAMITADO:
                return {
                    text: internalProcessStatusText.TRAMITADO,
                    class: "badge-warning",
                    color: "bg-processed text-white"
                };
            case internalProcessStatusEnum.CORRECAO:
                return {
                    text: internalProcessStatusText.CORRECAO,
                    class: "",
                    color: "bg-warning text-white "
                };
            case internalProcessStatusEnum.RESPONDIDO:
                return {
                    text: internalProcessStatusText.RESPONDIDO,
                    class: "",
                    color: "bg-success-light"
                };
            case internalProcessStatusEnum.VENCIDO:
                return {
                    text: internalProcessStatusText.VENCIDO,
                    class: "",
                    color: "bg-red text-white"
                };
            case internalProcessStatusEnum.ANULADO:
                return {
                    text: internalProcessStatusText.ANULADO,
                    class: "badge-danger",
                    color: "bg-red text-white"
                };
        }
    }

    public reopenInternalProcess(internalProcessId: number) {
        return this.http.get(this.apiURL() + '/api/internal-process/' + internalProcessId + '/reopen-internal-process', this.headers());
    }

    public mergedDocumentInPdf(files: ISendFileProcessPdf[], internalProcessId: number, internalProcessNumber: string) {
        const formData = new FormData()
        for (const fileElement of files) {
            formData.append(fileElement.name + ".pdf", fileElement.file)
        }   
        return this.http.post(this.apiURL() + '/api/pdf/internal-process/' + internalProcessId + '/' + internalProcessNumber , formData , { ...this.headers(true), responseType: 'blob'}); 
    } 
}
