import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { SubjectService } from 'src/app/app-core/services/subject.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { EvaluationTermService } from 'src/app/app-core/services/evaluation-term.service';
import { PersonService } from 'src/app/app-core/services/person.service';
import { UserService } from 'src/app/app-core/services/user.service';

import Stepper from 'bs-stepper';
import swal from 'sweetalert2';
import 'bootstrap/dist/js/bootstrap.bundle';
import { IOrganization } from 'src/app/interfaces/organization';
import { ISubject } from 'src/app/interfaces/subject';
import { SubjectAttachmentService } from 'src/app/app-core/services/subject-attachment.service';
import { typeFormRequestEnum } from 'src/app/Enums/TypeFormRequestEnum';
import { SignStatusEnum } from 'src/app/Enums/SignStatusEnum';
import { DocumentFormService } from 'src/app/app-core/services/document-form.service';
import { IAttachmentSubject } from 'src/app/interfaces/attachmentSubjects';
import { DocumentService } from 'src/app/app-core/services/document.service';
import { IDocumentFormRequest } from 'src/app/interfaces/documentRequestForm';
import { TagsService } from 'src/app/app-core/services/tags.service';
import { AutoridadeEnum } from 'src/app/Enums/autoridadeTypeEnum';
import { UserDataRequestComponent } from './components/user-data-request/user-data-request.component';
import { FormRequestDefaultComponent } from 'src/app/app-core/modules/forms/form-request-default/form-request-default.component';
import { AttachmentsRequestComponent } from './components/attachments-request/attachments-request.component';
import { UploadService } from 'src/app/app-core/services/upload.service';
import { IPdfData } from 'src/app/interfaces/signature-gov.interfaces';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

enum StepperPositionEnum {
    INICIO = 1,
    DETALHAMENTO = 2,
    DOCUMENTOS = 3,
    
    RESUMO = 4,
    DOCUMENTO_SOLICITACAO = 5
}

@Component({
    selector: 'app-new-request',
    templateUrl: './new-request.component.html',
    styleUrls: ['./new-request.component.scss'],
    providers: [
        RequestService,
        OrganizationService,
        SubjectService,
        EvaluationTermService,
        PersonService,
        UserService,
        SubjectAttachmentService,
        DocumentFormService,
        DocumentService,
        TagsService,
        CryptoService
    ],
})
export class NewRequestComponent extends GerinusBD implements OnInit, OnDestroy {
    @ViewChild('userData') userData: UserDataRequestComponent;
    @ViewChild('attachmentsRequest') attachmentsRequest: AttachmentsRequestComponent;
    @ViewChild('formDefault') formDefault: FormRequestDefaultComponent;

    termAccepted: boolean;

    public stepper: Stepper;
    public organizations: IOrganization[];
    public subjects: ISubject[] = [];
    public swal = swal;
    public userName;
    public selectedStep = 1;
    public nomeOrgao: string;
    public assuntoDescricao: string;
    public assuntoTipo: string;
    public currentDate: Date = new Date();
    public requiredFieldsFirstStep;
    public requiredFieldsSecondStep;
    public requiredFieldsFirstStepFilled: boolean;
    public requiredFieldsSecondStepFilled: boolean;
    public requiredFieldsThirdStepFilled: boolean;
    public mimeTypes: string[] = ['application/pdf'];
    public validateForm: boolean;
    public subjectForm: string;
    public attachments: IAttachmentSubject[];
    public modalName: string = 'solicitacao-sucesso';
    public havePdfBuffer = false;
    public saveDocumentData: any;
    public renderModalAccountLevel = false;
    public environment = environment;

    public stepperOptions = {
        requestData: {
            name: 'Início',
            stepper: 1,
            required: false,
            visible: true
        },
        detailing: {
            name: 'Detalhamento',
            stepper: 2,
            required: false,
            visible: true
        },
        attachment: {
            name: 'Documentos',
            stepper: 3,
            required: false,
            visible: true
        },
        summary: {
            name: 'Resumo',
            stepper: 4,
            required: false,
            visible: true
        },
        document: {
            name: 'Documento',
            stepper: 5,
            required: false,
            visible: false
        },
    };

    public documentForm: IPdfData;
    public iSrc: string;
    public tagSubjectName: string;
    public customNewRequest: string;
    public tagCodigo: number;
    public mimeTypeSupplementary: string[] = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    private termScreeningAccepted: boolean = false;
    private requestSaved = false;
    private documentCode: number;
    public pdfUploadedFileCloudFlare: string;
    public pdfFileCloudFlareUrl: string;
    public lastStepper: number;

    constructor(
        public requestService: RequestService,
        public global: GlobalService,
        public uploadService: UploadService,
        public organizationService: OrganizationService,
        public subjectService: SubjectService,
        public personService: PersonService,
        public userService: UserService,
        public tagsService: TagsService,
        public documentFormService: DocumentFormService,
        public documentService: DocumentService,
        public evaluationTermService: EvaluationTermService,
        private cryptoService: CryptoService,
        private route: ActivatedRoute
    ) {
        super(global, requestService);
        this.afterCreate();
        this.showEmptyData = false;
        this.showHeader = false;
        this.showPaginate = false;
        let stepperTimer = setInterval(() => {
            let stepper = document.getElementById('stepper');
            if (stepper) {
                clearInterval(stepperTimer);
                this.stepper = new Stepper(stepper, {
                    animation: true,
                    linear: false,
                });
            }
        }, 500);

        const title = document.querySelector('title');
        title.text = 'Nova Solicitação';
    }
    ngOnDestroy(): void {
        if (this.requestSaved === false) {
            this.deleteRequestRecords();
        }
    }

    ngOnInit() {
        this.add();
        this.customNewRequest = this.userService.isCidadao()
        ? 'mt-4 mt-md-5 mt-lg-4'
        : 'mt-4 mt-md-8 mt-lg-8';
    }

    public getNomeUsuario(personId: number) {
        this.personService.getEntity(personId).subscribe({
            next: (response: any) => {
                this.userName = response.entity.PE_Nome;
            },
        });
    }

    public deleteRequestRecords() {
        this.uploadService.deleteFile(this.pdfUploadedFileCloudFlare, 'documento', 'SIGN').subscribe({
            next: (response: any) => {
            }
        })
    }

    public defaults() {
        return {
            applicantRequest: this.userData?.form?.get('applicantRequest')?.value,
            assuntoDescricao: this.userData?.subjectName,
            solicitacao: {
                SOL_CodigoOrgao: this.userData?.solicitacaoFormGroup.get('SOL_CodigoOrgao').value,
                SOL_CodigoAssunto: this.userData?.solicitacaoFormGroup.get('SOL_CodigoAssunto').value,
                SOL_CodigoSolicitante: this.userData?.solicitacaoFormGroup.get('SOL_CodigoSolicitante').value,
                SOL_Status: 0,
                SOL_CodigoDocumento: null,
                SOL_Descricao: this.formDefault?.form.get('formVariants').get('SOL_Descricao')?.value,
                SOLH_Historico: 'A solicitação está em análise',
            },
            requerente: {
                SOLR_TipoInscricao: '2',
            },
            formularios: {},
            documentos: {
                procuracao: this.userData?.form.get("procuracao")?.value || [],
                requerimento: this.attachmentsRequest?.entity.documentos.requerimento || [],
                complementar: this.attachmentsRequest?.entity.documentos.complementar || [],
            },
            formularioTipo: this.userData?.typeForm,
        };
    }

    public nextStep() {

        if (this.selectedStep == StepperPositionEnum.RESUMO) {
            this.renderModalAccountLevel = true;
        }

        if (this.selectedStep != 5) {
            if (this.selectedStep > StepperPositionEnum.INICIO) this.renderPdf();

            this.selectedStep++;
            this.stepper.next();
            this.entity = this.synchronizeDataWithChildrenComponent();
        } else {
            this.save();
        }

        if (this.userData?.haveSign) {

            this.stepperOptions["document"].visible = true;
            this.lastStepper = this.stepperOptions["document"].stepper;

        }
        else {
            this.stepperOptions["document"].visible = false;
            this.lastStepper = this.stepperOptions["summary"].stepper;
        }
    }

    private synchronizeDataWithChildrenComponent() {
        return {
            ...this.entity,
            applicantRequest: this.userData?.form?.get('applicantRequest')?.value,
            assuntoDescricao: this.userData?.subjectName,
            solicitacao: {
                ...this.entity.solicitacao,
                SOL_CodigoOrgao: this.userData?.solicitacaoFormGroup.get('SOL_CodigoOrgao').value,
                SOL_CodigoAssunto: this.userData?.solicitacaoFormGroup.get('SOL_CodigoAssunto').value,
                SOL_CodigoSolicitante: this.userData?.solicitacaoFormGroup.get('SOL_CodigoSolicitante').value,
                SOL_Status: 0,
                SOL_Descricao: this.entity.formularios['formVariants']?.SOL_Descricao,
                SOL_CodigoDocumento: null,
                SOLH_Historico: 'A solicitação está em análise',
            },
            requerente: {
                ...this.entity.requerente,
                ...this.userData?.requerenteFormGroup?.getRawValue(),
            },
            documentos: {
                procuracao: this.userData?.form.get("procuracao")?.value || [],
                requerimento: this.attachmentsRequest?.entity.documentos.requerimento || [],
                complementar: this.attachmentsRequest?.entity.documentos.complementar || [],
            },
            formularioTipo: this.userData?.typeForm,
        };
    }

    public previousStep() {
        if (this.selectedStep === this.stepperOptions['requestData'].stepper) {
            if (this.userService.isCidadao()) {
                this.global.router.navigate(['/citizen']);
            } else if (this.userService.isTriagem()) {
                this.global.router.navigate(['/requests/evaluation']);
            } else {
                this.global.router.navigate(['/requests/ongoing']);
            }
        } else {
            this.selectedStep--;
            this.stepper.previous();
        }
    }

    openSwal(text: string) {
        this.swal.fire({
            title: 'Atenção!',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
        });
    }

    /*
     * Função que valida se todos os campos estão preenchidos corretamente.
     * Recebe um objeto com os campos obrigatórios de cada step
     */

    areFieldsFilled(fieldsObject: any): boolean {
        for (let field in fieldsObject) {
            if (fieldsObject[field] == null || fieldsObject[field] == 'null' || fieldsObject[field] == '') {
                return false;
            }
        }
        return true;
    }

    /*
     * Função que lida com o segundo step (Cadastro de Requerente)
     * Os campos são preenchidos ou nulos de acordo com a marcação do checkbox "solicitar para outra Pessoa"
     */

    toggleCheckTerm() {
        this.termAccepted = !this.termAccepted;
    }

    public save() {
        this.global.startLoading('Enviando solicitação...', true);
        if (this.entity.requerente) {
            this.entity.requerente.SOLR_TipoInscricao = parseInt(this.entity.requerente.SOLR_TipoInscricao);
        }

        let solicitacao = { ...this.entity };

        if (this.entity.formularios['formVariants']) {
            delete this.entity.formularios['formVariants'];
        }

        this.requestService.save(solicitacao).subscribe({
            next: (response: any) => {
                this.requestSaved = true;
                this.global.closeModalInfo('Documentos');
                this.global.showModalInfo(this.modalName);
                this.requestService.getEntity(response.entity.SOL_CodigoSolicitacao).subscribe({
                    next: (data: any) => {
                        this.requestService.generateRequestPDF(data.entity);
                        this.global.stopLoading();
                        this.global.closeModalInfo('Documentos');
                    },
                });
            },
            error: () => {
                this.requestSaved = false;
            }
        });
    }

    saveTriagemTermo() {
        if (!this.termScreeningAccepted) {
            this.termScreeningAccepted = true;

            const termo = {
                TT_CodigoUsuario: this.global.user().USR_CodigoUsuario,
                TT_Aceito: true,
            };

            this.evaluationTermService.save(termo).subscribe({
                next: () => {
                    this.global.closeModalInfo(this.modalName + '-termo');
                    this.entity.checkBoxMarked = true;
                },
            });
        }
    }

    getFormRequest(event) {
        this.entity.formularios = event.form;
        this.validateForm = event.validators;
    }

    listKeysStepperOptions() {
        return Object.keys(this.stepperOptions);
    }

    getEnumType(type: string): number {
        return typeFormRequestEnum[type];
    }

    renderPdfIptu() {
        const dataPDF = {
            subject: this.userData?.subjectName,
            org: this.userData?.orgao?.OR_Descricao,
            solicitante: this.entity.requerente,
            form: this.entity.formularios,
            description: this.entity.solicitacao.SOL_Descricao,
        };
        this.documentForm = this.documentFormService.generatorDocumentFormIptuPDF(dataPDF);
    }

    public renderPdfSEPLAG() {
        delete this.entity.formularios?.termoAceito;

        const contentRequerente = [
            {
                title: 'REQUERENTE: ',
                content:
                    this.documentFormService.formatCpfCnpj(this.entity.requerente.SOLR_CpfCnpj) +
                    ' - ' +
                    this.entity.requerente.SOLR_NomeRazaoSocial,
            },
            {
                title: 'TELEFONE: ',
                content: this.documentFormService.formatTelefone(this.entity.formularios.SOLS_RequerenteTelefone),
            },
            { title: 'RG: ', content: this.mascaraRg(this.entity.formularios.SOLS_RequerenteRG) },
            { title: 'ÓRGÃO EXPEDITOR: ', content: this.entity.formularios.SOLS_RequerenteRGOrgaoExpeditor },
            {
                title: 'DATA DE NASCIMENTO: ',
                content: this.documentFormService.formatData(this.entity.formularios.SOLS_RequerenteDataNascimento),
            },
            {
                title: 'ENDEREÇO: ',
                content:
                    this.entity.formularios.SOLS_RequerenteLogradouro +
                    ', ' +
                    this.entity.formularios.SOLS_RequerenteNumero +
                    ', ' +
                    this.entity.formularios.SOLS_RequerenteBairro +
                    ', ' +
                    this.entity.formularios.SOLS_RequerenteMunicipio +
                    ' - ' +
                    this.documentFormService.formatCEP(this.entity.formularios.SOLS_RequerenteCEP),
            },
        ];

        const contentLotacaoCargo = [
            { title: 'LOTAÇÃO: ', content: this.entity.formularios?.SOLS_RequerenteLotacao },
            { title: 'CARGO: ', content: this.entity.formularios?.SOLS_RequerenteCargo },
        ];

        const content = [
            { title: 'UNIDADE DE EXERCÍCIO: ', content: this.entity.formularios?.SOLS_RequerenteUnidadeExercicio },
            {
                title: 'DATA EF.EXERCÍCIO/ADM: ',
                content: this.documentFormService.formatData(this.entity.formularios?.SOLS_RequerenteDataExercicio),
            },
        ];

        const autoridadesLabels = {
            [AutoridadeEnum.SECRETARIO_INFRAESTRUTURA]: 'Secretário(a) de Infraestrutura',
            [AutoridadeEnum.SECRETARIO_EDUCACAO]: 'Secretário(a) de Educação',
            [AutoridadeEnum.SECRETARIO_REGEGIONAL_PECEM]: 'Secretário(a) Regional Pecém',
            [AutoridadeEnum.PREFEITO_MUNICIPAL]: 'Prefeito Municipal',
            [AutoridadeEnum.OUVIDOR]: 'Ouvidor(a)',
            [AutoridadeEnum.SECRETARIO_SAUDE]: 'Secretário(a) de Saúde',
            [AutoridadeEnum.CONTROLADOR]: 'Controlador(a)',
            [AutoridadeEnum.SECRETARIO_ADMINISTRACAO]: 'Secretário(a) de Administração',
            [AutoridadeEnum.SECRETARIO_GOVERNO]: 'Secretário(a) de Governo',
            [AutoridadeEnum.SECRETARIO_TRABALHO_SOCIAL]: 'Secretário(a) de Trab. e Des.Social',
            [AutoridadeEnum.PROCURADOR_GERAL]: 'Procurador(a) Geral',
            [AutoridadeEnum.SECRETARIO_GERAL]: 'Secretário(a) Geral',
            [AutoridadeEnum.SECRETARIO_FINANCAS]: 'Secretário(a) de Finanças',
            [AutoridadeEnum.COORDENADOR_FMPS]: 'Coordenador(a) do FMPS',
            [AutoridadeEnum.OUTROS]: 'Outros',
        };

        const contentBeneficiario = [
            {
                title: 'PARENTESCO COM EX-SERVIDOR: ',
                content: this.entity.formularios.SOLS_RequerenteParentescoExServidor,
            },
            {
                title: 'DATA DO ÓBITO: ',
                content: this.entity.formularios?.SOLS_RequerenteDataObito
                    ? this.documentFormService.formatData(this.entity.formularios?.SOLS_RequerenteDataObito)
                    : null,
            },
            {
                title: 'AUTORIDADE REQUERIDA: ',
                content: autoridadesLabels[this.entity.formularios?.SOLS_RequerenteAutoridadeRequerida],
            },
        ];

        const data: any[] = [
            {
                title: 'DADOS DO SOLICITANTE',
                contents: [
                    {
                        title: 'SOLICITANTE: ',
                        content:
                            this.documentFormService.formatCpfCnpj(this.global.user().PE_CPF) +
                            ' - ' +
                            this.global.user().PE_Nome,
                    },
                    { title: 'E-MAIL: ', content: this.global.user().PE_Email },
                ],
            },
            {
                title: 'DADOS DA SOLICITAÇÃO',
                contents: [
                    { title: 'ORGÃO: ', content: 'SEPLAG' },
                    { title: 'ASSUNTO: ', content: this.userData?.subjectName },
                ],
            },
            {
                title: 'DADOS DO REQUERENTE',
                contents: contentRequerente.filter((content) => content.content),
                height: null,
                columns: 1,
            },
        ];

        if (contentLotacaoCargo?.filter((content) => content?.content?.length > 0)?.length > 0) {
            data.push({
                title: null,
                contents: contentLotacaoCargo.filter((content) => content.content),
            });
        }

        if (content?.filter((content) => content?.content?.length > 0)?.length > 0) {
            data.push({
                title: null,
                contents: content.filter((content) => content.content),
            });
        }

        if (contentBeneficiario?.filter((content) => content?.content?.length > 0)?.length > 0) {
            data.push({
                title: null,
                contents: contentBeneficiario.filter((content) => content.content),
                height: null,
                columns: 2,
            });
        }

        data.push({
            title: null,
            contents: [
                {
                    title: null,
                    content:
                        'Declaro, para fins de direito, sob as penas da lei, que as informações acima prestadas e documentos são verdadeiros e autênticos. Atesto que estou ciente de que, se constatada falsidade ideológica ou documental, responderei civil e criminalmente, na forma do art. 299 e seguinte do código penal.',
                },
            ],
        });
        this.documentForm = this.documentFormService.generatorDocumentFormSEPLAG(data);
    }

    renderPdfInscricao() {
        const dataPDF = {
            subject: this.userData?.subjectName,
            org: this.userData?.orgao?.OR_Descricao,
            solicitante: this.entity.requerente,
            form: this.entity.formularios,
            description: this.entity.solicitacao.SOL_Descricao,
        };
        this.documentForm = this.documentFormService.generatorDocumentFormInscricao(dataPDF);
    }

    renderPdfDefault() {
        this.documentForm = this.documentFormService.generateDocumentFormDefault({
            solicitante: this.userData?.user,
            assunto: this.userData?.subjectName,
            orgao: this.userData?.orgao.OR_Descricao,
            description: this.entity.solicitacao.SOL_Descricao,
            applicantRequest: this.entity.applicantRequest,
            requerente: this.entity?.requerente,
        });
    }

    private mascaraRg(v) {
        v = v.replace(/\D/g, '');
        v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1$2$3-$4');
        return v;
    }

    openDocumentModal() {
        this.global.showModalInfo('Documentos');
    }

    saveDocumentAndSignature(event) {
        const date = new Date();
        this.entity.documentos["requerimento"].push({
            url: event.fileCloudFlare,
            name: event.fileCloudFlare,
            type: "application/pdf",
            typeDocument: "REQUERIMENTO"
        })

        this.saveDocumentData = {
            documentSave: {
                DOC_Assunto: this.userData?.subjectName,
                DOC_Exercicio: date.getFullYear(),
                DOC_Tipo: 'solicitacao',
                DOC_CodigoUsuario: this.global.user().USR_CodigoUsuario,
                DOC_Conteudo: event.fileCloudFlare,
                DOC_DocumentoBase64: event.base64Document,
                DOC_Hash: event.hash,
            },
            documentSignature: {
                DOCSIGN_CodigoPessoa: this.entity.solicitacao.SOL_CodigoSolicitante,
                DOCSIGN_Assinatura: event.fileCloudFlare,
                DOCSIGN_Certificado: event.cert
            },
            subscribers: [
                {
                    DOCAS_CodigoAssinante: this.global.user().USR_CodigoUsuario,
                },
            ],
        };
    }

    saveDocumentAndRequest() {
        this.global.startLoading('Salvando documento...', true);
        this.documentService.saveSignDocument(this.saveDocumentData).subscribe({
            next: (response: any) => {
                const documentId = response.result.id;
                this.entity.solicitacao.SOL_CodigoDocumento = documentId;
                this.global.stopLoading();
                this.save();
            },
        });

    }

    renderPdfITBI() {
        this.entity.solicitacao.SOL_Descricao = this.entity.formularios['formVariants'].SOL_Descricao;
        const dataPDF = {
            subject: this.userData?.subjectName,
            org: this.userData?.orgao?.OR_Descricao,
            solicitante: this.entity.requerente,
            form: this.entity.formularios,
            description: this.entity.solicitacao.SOL_Descricao,
        };
        this.documentForm = this.documentFormService.generatorDocumentFormITBI(dataPDF);
    }

    renderPdfSEMURB() {
        const dataPDF = {
            subject: this.userData?.subjectName,
            org: this.userData?.orgao?.OR_Descricao,
            solicitante: this.entity.requerente,
            form: this.entity.formularios,
            description: this.entity.solicitacao.SOL_Descricao,
        };

        this.documentForm = this.documentFormService.generateDocumentFormSEMURB(dataPDF);

        this.global.showModalInfo('Documentos');
    }

    public get enableNextStep() {
        switch (this.selectedStep) {
            case StepperPositionEnum.INICIO:
                return this.userData?.form?.invalid;

            case StepperPositionEnum.DETALHAMENTO:
                if (this.global.govBr) {
                    return this.formDefault?.form?.invalid;
                } 

                return !this.validateForm;

            case StepperPositionEnum.DOCUMENTOS:
                if (this.attachmentsRequest?.attachments.length == 0) {
                    return !(this.attachmentsRequest?.entity?.documentos.requerimento.length > 0);
                } 

                return !this.attachmentsRequest?.isAttached;
            
            default:
                return false;
        }
    }

    public renderPdf() {

        if (!environment.production) {
            switch (this.entity?.formularioTipo) {
                case typeFormRequestEnum.IPTU:
                    this.renderPdfIptu();
                    break;
                case typeFormRequestEnum.SEPLAG:
                    this.renderPdfSEPLAG();
                    break;
                case typeFormRequestEnum.INSCRICAO:
                    this.renderPdfInscricao();
                    break;
                case typeFormRequestEnum.ITBI:
                    this.renderPdfITBI();
                    break;
                case typeFormRequestEnum.SEMURB:
                    this.renderPdfSEMURB();
                    break;
                case null:
                    this.renderPdfDefault();
                    break;
            }
        }
        else {
            this.renderPdfDefault();
        }
        
        this.havePdfBuffer = true;
    }

    public getcheckFormType(type: number) {
        return this.userData?.form?.get('typeForm').value == typeFormRequestEnum[type];
    }

    public checkMask(value: string): string {
        const valueSize = value.length;
        if (valueSize == 11) {
            return '000.000.000-00'
        }
        return "00.000.000/0000-00"
    }

    public get saveRequestRule() {
        return this.selectedStep == this.stepperOptions["summary"].stepper && !this.userData?.haveSign
    }

}
