import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OversightOrganizationService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'orgao_fiscalizador';
        this.idField = 'ORFI_CodigoOrgaoFiscalizador';
        this.model = 'OrgaoFiscalizadorModel';
    }

    public getCnpjOrgaoFiscalizador(cnpj: any): Observable<any> {
        return this.http.get(this.apiURL() + `/api/oversight-organization/${cnpj}/get-cnpj-oversight-organization/`, this.headers())
    }
}
