import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { DepartmentService } from 'src/app/app-core/services/department.service';
import { RequestCorrectionService } from 'src/app/app-core/services/requestCorrection.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';

// External Libraries
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import * as moment from 'moment';

// Interfaces
import { ICancelRequestSchema, Request } from 'src/app/interfaces/request';
import { IDepartment } from 'src/app/interfaces/department';
import { IListScopeDepartmentResponse } from 'src/app/interfaces/listScope/listScopeDepartmentResponse';
import { IGetEntityRequestResponse } from 'src/app/interfaces/getEntity/getEntityRequestResponse';
import { IListScopeRequestCorrectionResponse } from 'src/app/interfaces/listScope/listScopeRequestCorrectionResponse';
import { IRequestCorrection } from 'src/app/interfaces/requestCorrection';
import { IGetEntityRequestCorrectionResponse } from 'src/app/interfaces/getEntity/getEntityRequestCorrectionResponse';
import { MessageService } from 'primeng/api';
import { ReturnRequestTypeService } from 'src/app/app-core/services/return-request-type.service';

//Enum
import { RequestStatusEnum } from 'src/app/Enums/RequestStatusEnum';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from 'src/app/app-core/services/upload.service';
import { environment } from 'src/environments/environment';

enum keysDocumentosEnum {
    COMPLEMENTAR = 'complementar',
    PROCURACAO = 'procuracao',
    REQUERIMENTO = 'requerimento',
}
@Component({
    selector: 'app-view-correction-requests',
    templateUrl: './view-correction-requests.component.html',
    styleUrls: ['./view-correction-requests.component.scss'],
    providers: [
        RequestService,
        DepartmentService,
        GlobalService,
        RequestCorrectionService,
        ReturnRequestTypeService,
        ProcessService,
        UserService,
        MessageService,
        UploadService
    ],
})
export class ViewCorrectionRequestsComponent extends GerinusBD implements OnInit {
    public listReturnRequestType = [];
    public type: string;
    public selectedType: string = '';
    public mimeTypes: string[] = ['application/pdf'];
    public request: Request;
    public showCorrectionInfo: boolean = false;
    public departments: IDepartment[] = [];
    public modalName: string = 'solicitacao-actions';
    public requestCorrections: IRequestCorrection[] = [];
    public lastDescription: any;
    public swal = Swal;
    public process = {
        department: '',
        PRC_CodigoSolicitacao: 0,
        SOL_Autorizante: 0,
        PRCH_Historico: '',
        PRC_DataRecebimento: new Date(this.global.currentDate.setHours(this.global.currentDate.getHours() - 3)),
    };
    public hasUserUploadedFile: boolean = false;
    public reasonCancel: string;

    public proccessNumber: string;
    public correctionData: Date;
    public requestId: string;
    public customRequest: string;
    

    public documentCloudflareUrl: string;
    public textAreaDisabled: boolean = true;
    public selectedReason: string = '';
    public selectedCancellation = '';

    typeRequestReturn = [
        { value: 'DocumentacaoCorrompida', label: 'Documentação Corrompida' },
        { value: 'DocumentacaoIncompleta', label: 'Documentação Incompleta' },
        { value: 'AssinaturaResponsavelIncompleta', label: 'A assinatura do responsável não está devidamente preenchida' },
        { value: 'RequerimentoIncompleto', label: 'O requerimento do formulário está incompleto' },
        { value: 'AusenciaProcuracoes', label: 'Ausência de Procurações' },
        { value: 'Outros', label: 'Outros' }
    ]

    isDescriptionDisabled: boolean = false;

    public urlDocumentSanitizer;
    public alreadyFilteredList = {}
    public linkValidator = environment.projectURL == 'https://web.protocolize.pmsga.ce.gov.br' ? 'https://validar.iti.gov.br/index.html' : 'https://h-validar.iti.gov.br/index.html'

    public reasons = [
        { value: 'Preenchimento Incorreto do Assunto', label: 'Preenchimento incorreto do assunto' },
        { value: 'Documentação Insuficiente', label: 'Documentação insuficiente' },
        { value: 'Verificar Situação com o Órgão', label: 'Verificar situação com o Órgão' },
        { value: 'Múltiplas Solicitações/Processo', label: 'Múltiplas solicitações/processo' },
        { value: 'Criação de Solicitação de Empresa por Pessoa Física', label: 'Criação de solicitação de empresa por pessoa física' }
    ];

    public reasonMessage: string = 'Prezado Contribuinte. Após um processo de análise, sua solicitação será anulada. A equipe de triagem observou e encontrou empecilhos que incapacitaram o andamento da solicitação. Foi detectado o seguinte problema:'

    constructor(
        public global: GlobalService,
        public route: ActivatedRoute,
        private router: Router,
        public requestService: RequestService,
        public departmentService: DepartmentService,
        public correctionService: RequestCorrectionService,
        public returnRequestTypeService: ReturnRequestTypeService,
        public proccessService: ProcessService,
        public userService: UserService,
        private cryptoService: CryptoService,
        private sanitizer: DomSanitizer,
        private uploadService: UploadService
    ) {
        super(global, requestService);
        this.showEmptyData = false;
        this.showHeader = false;
        this.showPaginate = false;
        this.afterCreate();

        this.route.params.subscribe(() => {
            const params = this.route.snapshot.paramMap;
            this.type = params.get('type') || this.route.snapshot.url[0]?.path;

            try {
                this.requestId = this.cryptoService.decrypt(String(params.get('id')));
                if (!this.requestId) {
                    this.handleRedirect();
                }
                this.getRequest(parseInt(this.requestId));
            } catch (error) {
                this.handleRedirect();
            }
        });

        this.add();
        this.getListReturnRequestType();
    }

    ngOnInit(): void {
        const title = document.querySelector('title');

        title.text = 'Solicitação N° ' + this.requestId;
        this.getLastCorrection(parseInt(this.requestId));
        this.updateDescription();

        this.customRequest = this.userService.isCidadao()
        ? 'mt-4 mt-md-5 mt-lg-4'
        : 'mt-4 mt-md-8 mt-lg-8';
    }

    public defaults() {
        return {
            correctionInfoUser: {
                isCidadao: true,
                idField: 0,
                SOLC_DataCorrecao: new Date(this.global.currentDate.setHours(this.global.currentDate.getHours() - 3)),
                SOLC_Status: 1,
                SOL_Status: 0,
                SOLH_Historico: 'A solicitação foi reenviada para análise.',
            },
            documentos: {
                procuracao: [],
                requerimento: [],
                complementar: [],
            },
            correctionInfo: {
                idField: '',
                SOLH_Historico: 'A solicitação foi movida para correção de pendencias',
                SOLC_Status: 0,
                SOLC_DataLimiteCorrecao: moment(),
                SOL_Status: 2,
                SOLC_Descricao: null,
            },
        };
    }

    private handleRedirect(): void {

        this.router.navigate([`/requests/${this.userService.isTriagem() ? 'evaluation' : 'ongoing'}`], { queryParams: { 'not-found': true } });
    }

    getListReturnRequestType() {
        this.returnRequestTypeService.listAll(null, 0).subscribe({
            next: (response: any) => {
                this.listReturnRequestType = response.data
            }
        });
    }

    getLastCorrection(requestId: number) {
        this.correctionService.listAll(`"SOLC_CodigoSolicitacao" = ${requestId}`).subscribe({
            next: (response: IListScopeRequestCorrectionResponse) => {
                this.requestCorrections = response.data;
                this.lastDescription = this.requestCorrections[this.requestCorrections.length - 1];
            },
        });
    }

    getRequest(requestId: number) {
        this.requestService.getEntity(requestId).subscribe({
            next: (response: IGetEntityRequestResponse) => {
                this.request = response.entity;
                this.uploadService.download(this.request.DOC_Conteudo, 'SIGN').subscribe({
                    next: (response: any) => {
                        this.documentCloudflareUrl = response.url; 
                    }
                })
                this.checkRequestRouter(this.request);
                this.departmentService.listAll(`"DEP_CodigoOrgao" = ${this.request.SOL_CodigoOrgao}`).subscribe({
                    next: (response: IListScopeDepartmentResponse) => {
                        this.departments = response.data.filter(dep =>
                            dep.DEP_TipoProcessoAceito === 1 || dep.DEP_TipoProcessoAceito === 3
                        );

                        const subjectDepartment = this.request.AS_CodigoDepartamento;

                        if (subjectDepartment) {
                            this.process.department = String(subjectDepartment);
                        }
                    },
                });
            },
        });

        return this.request;
    }

    checkRequestRouter(request: Request) {
        if (this.type === 'edit' && request.SOL_Status == 0) {
            this.type = 'view';

            this.global.router.navigate([`/requests/${this.type}/${this.request.SOL_CodigoSolicitacao}`]);
        }
    }

    public save() {
        this.entity.correctionInfo.SOLC_CodigoSolicitacao = this.request.SOL_CodigoSolicitacao;
        this.entity.correctionInfo.idField = 0;
        this.entity.correctionInfo.SOLC_DataLimiteCorrecao = moment()
            .add(this.request.AS_PrazoCorrecao, 'days')
            .format('YYYY-MM-DD HH:mm:ss');

        this.entity.correctionInfo.PE_CodigoPessoa = this.global.user().PE_CodigoPessoa;

        this.global.startLoading('Processando...', true);
        this.correctionService.save(this.entity.correctionInfo).subscribe({
            next: (response: IGetEntityRequestCorrectionResponse) => {
                this.global.stopLoading();
                this.correctionData = new Date(response.entity.created_at);
                this.global.showModalInfo(`${this.modalName}-send-correction`);
            },
        });
    }

    scrollBottom() {
        $('html, body').animate(
            {
                scrollTop: $(document).height(),
            },
            1000,
        );
    }

    public getUploadedFile(event: any, attachment = null) {
        let file = event.file;

        if (
            file.typeDocument != keysDocumentosEnum.PROCURACAO &&
            file.typeDocument != keysDocumentosEnum.COMPLEMENTAR
        ) {
            file = {
                ...file,
                ANX_Obrigatorio: attachment.ANX_Obrigatorio,
            };
            this.entity.documentos[keysDocumentosEnum.REQUERIMENTO].push(file);
        } else {
            this.entity.documentos[file.typeDocument].push(file);
        }

        this.hasUserUploadedFile = true;
    }

    sendCorrection() {
        let isAllDocumentsValid = true;
        let missingDocument = '';

        const updatedRequest = this.getRequest(this.request.SOL_CodigoSolicitacao);

        for (const document of updatedRequest.documentos[keysDocumentosEnum.REQUERIMENTO]) {
            if (document.SOLA_Status == 0 && Object.keys(this.entity.documentos.requerimento).length === 0) {
                isAllDocumentsValid = false;
                missingDocument = document.SOLA_Tipo;
            }
        }

        for (const document of updatedRequest.documentos[keysDocumentosEnum.PROCURACAO]) {
            if (
                document.SOLA_Status == 0 &&
                Object.keys(this.entity.documentos.procuracao).length === 0 &&
                this.request.SOLR_CpfCnpj != ''
            ) {
                isAllDocumentsValid = false;
                missingDocument = document.SOLA_Tipo;
            }
        }

        if (isAllDocumentsValid) {
            this.entity.correctionInfoUser.SOLC_CodigoSolicitacao = this.request.SOL_CodigoSolicitacao;
            this.entity.correctionInfoUser.idField = this.lastDescription.SOLC_CodigoCorrecao;
            this.entity.correctionInfoUser.PE_CodigoPessoa = this.global.user().PE_CodigoPessoa;
            this.global.startLoading('Processando...', true);
            this.entity.correctionInfoUser.SOL_Status = RequestStatusEnum.corrected;

            this.correctionService
                .save({
                    ...this.entity.correctionInfoUser,
                    documentos: this.entity.documentos,
                })
                .subscribe({
                    next: () => {
                        this.global.stopLoading();
                        if (this.userService.isCidadao()) {
                            this.global.router.navigate(['/citizen']);
                        } else {
                            this.global.router.navigate(['/requests/ongoing']);
                        }
                    },
                });
        } else {
            Swal.fire({
                title: 'Atenção',
                text: `Você deve informar um documento de ${missingDocument} válido!`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#417bff',
                cancelButtonText: 'OK',
                showConfirmButton: false,
            });
        }
    }

    async saveProcess() {
        this.process.PRC_CodigoSolicitacao = this.request.SOL_CodigoSolicitacao;
        this.process.PRCH_Historico = 'O processo foi criado';
        this.global.startLoading('Processando...', true);
        this.process.SOL_Autorizante = this.global.user().USR_CodigoUsuario

        this.proccessService.save(this.process).subscribe({
            next: async () => {
                this.global.stopLoading();
                this.global.router.navigate(['/process/all']);
                this.global.closeModalInfo(this.modalName);
            },
        });
    }

    public onDeleteFileUploaded(event: any) {
        if (
            event.typeDocument != keysDocumentosEnum.COMPLEMENTAR &&
            event.typeDocument != keysDocumentosEnum.PROCURACAO
        ) {
            this.entity.documentos[keysDocumentosEnum.REQUERIMENTO].splice(
                this.entity.documentos[keysDocumentosEnum.REQUERIMENTO].length - 1,
                1,
            );
        } else {
            this.entity.documentos[event.typeDocument].splice(this.entity.documentos[event.typeDocument].length - 1, 1);
        }

        this.checkDocuments();
    }

    private checkDocuments() {
        const updatedRequest = this.getRequest(this.request.SOL_CodigoSolicitacao);

        if (this.entity.documentos.requerimento.length === 0) {
            this.hasUserUploadedFile = false;
        }

        for (const document of updatedRequest.documentos[keysDocumentosEnum.REQUERIMENTO]) {
            if (document.SOLA_Status == 0) {
                this.hasUserUploadedFile = false;
            }
        }

        for (const document of updatedRequest.documentos[keysDocumentosEnum.PROCURACAO]) {
            if (document.SOLA_Status == 0 &&
                Object.keys(this.entity.documentos.procuracao).length === 0 &&
                this.request.SOLR_CpfCnpj != '') {
                this.hasUserUploadedFile = false;
            }
        }
    }

    filterDocumentByType(type: string) {
        const list = this.request.documentos.requerimento.filter((document) => document.SOLA_Tipo === type);
        return list;
    }

    isNewDocumentType(type: string, index: number): boolean {
        if (!this.alreadyFilteredList[type]) {
            this.alreadyFilteredList[type] = index;
            return true;
        }
        return this.alreadyFilteredList[type] === index;
    }

    public showModalCorrectionDetails() {
        this.global.showModalInfo('correction-details');
    }

    public isIPTU(): boolean {
        return this.request.TAG_Nome == 'IPTU' ? true : false;
    }

    openPdfRequest(): void {
        this.global.showModalInfo('documents')
    }

    showModalCancelRequest() {
        this.global.showModalInfo('cancel-request');
    }

    handleCancelRequest() {
        this.global.closeModalInfo('cancel-request');

        const fullReason = this.selectedCancellation === 'outros'
            ? `${this.reasonCancel}`
            : `${this.reasonMessage} ${this.selectedCancellation}`

        const cancelRequestData: ICancelRequestSchema = {
            requestId: Number(this.requestId),
            reasonCancel: fullReason,
            userId: this.global.user().USR_CodigoUsuario
        }

        this.requestService.cancelRequest(cancelRequestData).subscribe({
            next: () => {
                this.global.router.navigate(['/requests/canceled'])
            }
        })
    }

    updateDescription() {
        if (this.selectedType == 'Outros') {
            this.entity.correctionInfo.SOLC_Descricao = '';
            this.isDescriptionDisabled = false;
        }
        else {
            this.entity.correctionInfo.SOLC_Descricao = this.selectedType;
            this.isDescriptionDisabled = true;
        }
    }
    
    public organizationRequerimentoRule(type: string, org: string) {
        return type == 'REQUERIMENTO' && this.documentCloudflareUrl?  'SIGN' : org;
    }
    
    updateMessage() {
        if (this.selectedCancellation === 'outros') {
            this.reasonCancel = '';
            this.textAreaDisabled = false;
        } else {
            const selectReason = this.reasons.find(reason => reason.value === this.selectedCancellation);
            this.reasonCancel = selectReason ? `${this.reasonMessage} ${selectReason.label}` : '';
            this.textAreaDisabled = true;
        }
    }

    downloadDocuments(name: string) {
        this.uploadService.download(name, 'SIGN').subscribe({
            next: (response: any) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            },
            error: (error: any) => {
                console.error('Erro no download:', error);
            },
        });
    }

    public get canFixDocuments(): boolean {
        return this.request.SOL_CodigoSolicitante == this.global.user().PE_CodigoPessoa ||
            (this.userService.isTriagem() && this.request.createdByGroup === 'TRIAGEM')
    }
}
