import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { RequestHistoryService } from 'src/app/app-core/services/requestHistory.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { PersonService } from 'src/app/app-core/services/person.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { ProcessService } from 'src/app/app-core/services/process.service';

import { RequestStatusEnum } from 'src/app/Enums/RequestStatusEnum';
import { RequestsConditionsEnum } from 'src/app/Enums/ListConditionsRequests';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { IRequestStatuses } from 'src/app/interfaces/request.interface';
import { ISubject } from 'src/app/interfaces/subject';
import { SubjectService } from 'src/app/app-core/services/subject.service';
import { IListScopeSubjectResponse } from 'src/app/interfaces/listScope/listScopeSubjectResponse';
import { Dropdown } from 'primeng/dropdown';
import { FilterService, IRequestFilters } from 'src/app/app-core/services/filter.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss'],
    providers: [
        RequestService,
        RequestHistoryService,
        UserService,
        PersonService,
        OrganizationService,
        ProcessService,
        MessageService,
        SubjectService,
        FilterService
    ],
})
export class RequestsComponent extends GerinusBD implements OnInit {
    @ViewChild('subjectDropdown') subjectDropdown: Dropdown;


    public isTableHistoryOpen: boolean[] = [];
    public typeRequestParam: string;
    public historics: any[] = [];
    public modalName: string = 'triagem_termo';
    public today: Date = new Date();


    //FILTROS
    public isFiltersModalOpen: boolean = false;
    public rangeDates: Date[] | undefined;
    public requestStatuses: IRequestStatuses[];
    public selectedStatuses: IRequestStatuses[];
    public requestNumber: string;
    public requester: string;
    public title: string;
    public subjects: ISubject[];
    public selectedSubject: string;
    public requestFilters: IRequestFilters

    constructor(
        public requestService: RequestService,
        public global: GlobalService,
        private activatedRoute: ActivatedRoute,
        private historicService: RequestHistoryService,
        public userService: UserService,
        public personService: PersonService,
        public organizationService: OrganizationService,
        private cryptoService: CryptoService,
        private processService: ProcessService,
        private messageService: MessageService,
        private config: PrimeNGConfig,
        private router: Router,
        private subjectService: SubjectService,
        private filterService: FilterService
    ) {
        super(global, requestService);
    }

    ngOnInit() {
        this.config.setTranslation({
            apply: 'Aplicar',
            clear: 'Limpar',
            accept: 'Sim',
            reject: 'Não',
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje'
        });


        this.activatedRoute.queryParams.subscribe(params => {
            this.requestNumber = params['requestNumber'] || '';

            if (typeof params['statuses'] === 'string' && params['statuses'] !== '') {
                const statusArray = params['statuses'].includes(',') ? params['statuses'].split(',') : [params['statuses']];

                this.selectedStatuses = this.requestStatuses && this.requestStatuses.length > 0
                    ? statusArray.map(value => {
                        return this.requestStatuses.find(status => status.value === +value);
                    }).filter(status => status !== undefined)
                    : [];
            } else {
                this.selectedStatuses = [];
            }

            if (typeof params['dateRange'] === 'string' && params['dateRange'] !== '') {
                this.rangeDates = params['dateRange'].split(',').map(date => new Date(date));
            }
            else {
                this.rangeDates = [];
            }

            this.requester = params['requester'] || '';
            this.selectedSubject = params['subject'] || '';

            this.listAll();

            this.typeRequestParam = params['type'] || this.activatedRoute.snapshot.url[0]?.path;

            this.requestStatuses = this.getStatusesByParam(this.typeRequestParam);

            const title = document.querySelector('title');
            title.text =
                this.userService.isAdmin() && this.typeRequestParam == 'correcao'
                    ? 'Solicitações Aguardando Correção'
                    : 'Consulta de Solicitações';
        });

        this.getSubjects();
    }

    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {

        const clickedInsideSubjectDropdown = this.subjectDropdown.containerViewChild.nativeElement.contains(event.target);

        if (!clickedInsideSubjectDropdown) {
            this.subjectDropdown.hide();
        }
    }

    add() {
        this.global.router.navigate(['/requests/new']);
    }

    getHistorics(requestId: number, index: number) {
        if (this.isTableHistoryOpen[requestId]) {
            this.historics.splice(index, 1);
        } else {
            this.historicService.listAll(`"SOLH_CodigoSolicitacao" = ${requestId}`).subscribe({
                next: (response: any) => {
                    this.historics.push({
                        data: response.data,
                        index,
                    });
                },
            });
        }
    }

    historyFilter(index: number) {
        return this.historics.filter((historico) => {
            return historico.index === index;
        });
    }

    getStatusClass(status: number) {
        return {
            triagem: status === RequestStatusEnum.evaluation,
            aceita: status === RequestStatusEnum.accepted,
            correcao: status === RequestStatusEnum.correction,
            anulada: status === RequestStatusEnum.canceled,
            corrigida: status === RequestStatusEnum.corrected
        };
    }

    getStatusesByParam(param: string) {

        if (param === 'ongoing') {
            return [
                { name: 'Triagem', value: 0 },
                { name: 'Em Correção', value: 2 },
            ];
        }

        return [
            { name: 'Triagem', value: 0 },
            { name: 'Aceitas', value: 1 },
            { name: 'Em Correção', value: 2 },
            { name: 'Anuladas', value: 3 }
        ];
    }

    getStatusDescription(currentRequestStatus: number) {
        const statusDescriptions = {
            0: 'Triagem',
            1: 'Aceita',
            2: 'Em correção',
            3: 'Anulada',
            4: 'Corrigida'
        };

        return statusDescriptions[currentRequestStatus];
    }

    handleCollapseRow(index: number) {
        this.isTableHistoryOpen[index] = !this.isTableHistoryOpen[index];
    }

    public handleNavigate(requestStatus: number, requestId: number) {
        let typeRouter: string = 'view';

        if (!this.userService.isAdmin() && requestStatus === 2) {
            typeRouter = 'edit';
        }

        const encryptedRequestId = this.cryptoService.encrypt(String(requestId));

        this.global.router.navigate([`/requests/${typeRouter}/${encryptedRequestId}`]);
    }

    public handleNavigateToProcess(processId: number) {
        const encryptedId = this.cryptoService.encrypt(String(processId));
        this.global.router.navigate([`/process/view/${encryptedId}`]);
    }

    public listCondition() {
        const user = this.global.user();
        const organizationId = user.USR_CodigoOrgao;
        const personId = user.PE_CodigoPessoa;
        const triagemDepartamento = user.OR_TriagemDepartamento;
        let conditions = '';

        if (this.userService.isAdmin()) {
            if (this.typeRequestParam === RequestsConditionsEnum.PROCESSES) {
                this.title = 'Solicitações Processadas';
                conditions = `"SOL_Status" = 1`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.EXPIRED) {
                this.title = 'Solicitações Vencidas';
                conditions = `"SOLC_DataLimiteCorrecao" < NOW() and "SOL_Status" = 2`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.REQUEST) {
                this.title = 'Solicitações em Análise';
                conditions = `("SOL_Status" = 0 or "SOL_Status" = 4 or ("SOL_Status" = 2 and "SOLC_Status" = 0))`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.CANCELED) {
                this.title = 'Solicitações Anuladas';
                conditions = `"SOL_Status" = 3`;
            }
        }

        if (this.userService.isTriagem()) {
            if (triagemDepartamento) {
                if (this.typeRequestParam === 'correction') {
                    conditions = `"SOL_Status" = 2 and "AS_CodigoDepartamento" = ${user.DEP_CodigoDepartamento}`;
                }
                else if (this.typeRequestParam === 'evaluation') {
                    conditions = `("SOL_Status" = 0 or "SOL_Status" = 4) and "AS_CodigoDepartamento" = ${user.DEP_CodigoDepartamento}`;
                }
                else if (this.typeRequestParam === RequestsConditionsEnum.CANCELED) {
                    conditions = `"SOL_Status" = 3 and "AS_CodigoDepartamento" = ${user.DEP_CodigoDepartamento}`;
                }
            } else {
                if (this.typeRequestParam === 'correction') {
                    conditions = `"SOL_Status" = 2 and "SOL_CodigoOrgao" = ${organizationId}`;
                }
                else if (this.typeRequestParam === 'evaluation') {
                    conditions = `("SOL_Status" = 0 or "SOL_Status" = 4) and "SOL_CodigoOrgao" = ${organizationId}`;
                }
                else if (this.typeRequestParam === RequestsConditionsEnum.CANCELED) {
                    conditions = `"SOL_Status" = 3 and "SOL_CodigoOrgao" = ${organizationId}`;
                }
            }
        }

        if (this.userService.isDiretorDepartamento() || this.userService.isSecretario() || this.userService.isAdmOrgao()) {
            if (this.typeRequestParam === RequestsConditionsEnum.EXPIRED) {
                conditions = `"SOLC_DataLimiteCorrecao" < NOW() and "SOL_Status" = 2 and "SOL_CodigoOrgao" = ${organizationId}`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.REQUEST) {
                conditions = `("SOL_Status" = 0 or "SOL_Status" = 4 or ("SOL_Status" = 2 and "SOLC_Status" = 0)) and "SOL_CodigoOrgao" = ${organizationId}`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.PROCESSES) {
                conditions = `"SOL_Status" = 1 and "SOL_CodigoOrgao" = ${organizationId}`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.CANCELED) {
                conditions = `"SOL_Status" = 3 and "SOL_CodigoOrgao" = ${organizationId}`;
            }
        }

        if (this.userService.isCidadao()) {
            if (this.typeRequestParam === RequestsConditionsEnum.PROCESSES) {
                conditions = `"SOL_Status" = 1 and "SOL_CodigoSolicitante" = ${personId}`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.EXPIRED) {
                conditions = `"SOLC_DataLimiteCorrecao" < NOW() and "SOL_Status" = 2 and "SOL_CodigoSolicitante" = ${personId}`;
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.REQUEST) {
                if (this.global.user().USG_CodigoGrupoOriginal === 2) {
                    conditions = `("SOL_Status" = 0 or "SOL_Status" = 4 or ("SOL_Status" = 2 and "SOLC_Status" = 0)) and "SOLR_CodigoRequerente" IS NULL and "SOL_CodigoSolicitante" = ${personId}`;
                } else {
                    conditions = `("SOL_Status" = 0 or "SOL_Status" = 4 or ("SOL_Status" = 2 and "SOLC_Status" = 0)) and "SOL_CodigoSolicitante" = ${personId}`;
                }
            }
            else if (this.typeRequestParam === RequestsConditionsEnum.CANCELED) {
                conditions = `"SOL_Status" = 3 and "SOL_CodigoSolicitante" = ${personId}`;
            }
        }

        const additionalFilters = this.filter()
        if (additionalFilters) {
            conditions += ` AND ${additionalFilters}`;
        }

        return conditions;
        
    }

    public get titleRequests(): string {

        const title = {
            ongoing: 'Solicitações Em Análise',
            expired: 'Solicitações Vencidas',
            processes: 'Solicitações Processadas',
            correction: 'Solicitações Aguardando Correção',
            evaluation: 'Solicitações em Análise',
            canceled: 'Solicitações Anuladas'
        }

        return title[this.typeRequestParam] ?? this.title
    }

    public filter() {
        this.requestFilters = {
            requestNumber: this.requestNumber,
            statuses: this.selectedStatuses ? this.selectedStatuses.map(status => status.value).join(',') : '',
            dateRange: this.rangeDates ? this.rangeDates.filter(date => date != null).map(date => date.toISOString()).join(',') : '',
            requester: this.requester,
            subject: this.selectedSubject,
        }

        const filterConditions = this.filterService.getRequestsFilterConditions(this.requestFilters);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: this.requestFilters,
            queryParamsHandling: 'merge',
        });

        return filterConditions;
    }

    fetchAllRequestsFiltered() {
        this.isFiltersModalOpen = false;
        this.listAll();
    }

    public get isClearFilterButtonVisible(): boolean {
        const isRequestNumberEmpty = !this.requestNumber || this.requestNumber.trim() === '';
        const isSelectedStatusesEmpty = !this.selectedStatuses || this.selectedStatuses.length === 0;
        const isRangeDatesEmpty = !this.rangeDates || this.rangeDates.length === 0 || this.rangeDates.every(date => date == null);
        const isRequesterEmpty = !this.requester || this.requester.trim() === '';
        const isSubjectEmpty = !this.selectedSubject || this.selectedSubject.trim() === '';

        return !(
            isRequestNumberEmpty &&
            isSelectedStatusesEmpty &&
            isRangeDatesEmpty &&
            isRequesterEmpty &&
            isSubjectEmpty
        );
    }

    clearFilters() {
        this.requestNumber = null,
            this.selectedStatuses = null,
            this.rangeDates = null,
            this.requester = null,
            this.selectedSubject = null

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
        });

        this.listAll();

        this.isFiltersModalOpen = false;
    }

    getSubjects() {
        this.subjectService.listAll('', 0).subscribe({
            next: (response: IListScopeSubjectResponse) => {
                this.subjects = response.data;

                if (
                    this.userService.isAdmOrgao() ||
                    this.userService.isDiretorDepartamento() ||
                    this.userService.isSecretario() ||
                    this.userService.isMembroDepartamento()
                ) {
                    this.subjects = this.subjects.filter(subject => subject.AS_CodigoOrgao === this.global.user().USR_CodigoOrgao)
                }
            }
        })
    }

    public get reportButtonRule() {
        return this.userService.isAdmin() || this.userService.isAdmOrgao() || this.userService.isDiretorDepartamento() || this.userService.isSecretario();
    }

    generateServiceListPDF() {
        let title = this.titleRequests;
        let condition = this.filterCondition();

        if (this.userService.isAdmOrgao() || this.userService.isSecretario()) {
            title += ` - ${this.global.user().OR_Descricao}`
            condition = `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao} AND ${this.filterCondition()}`
        }

        const formatDate = (date:  any) => {
            return new DatePipe('pt-BR').transform(date, 'yyyy-MM-dd HH:mm:ss', 'UTC');
        }

        this.requestService.generateListPDF(
            title.toUpperCase(),
            {
                titles: ['NÚMERO', 'CPF', 'SOLICITANTE', 'ASSUNTO', 'STATUS', 'DATA DA SOLICITAÇÃO'],
                columns: [
                    this.requestService.columnObject('SOL_NumeroSolicitacao'), 
                    this.requestService.columnObject('PE_CPF', this.requestService.formatCpfCnpj), 
                    this.requestService.columnObject('PE_Nome'),
                    this.requestService.columnObject('AS_Descricao'), 
                    this.requestService.columnObject('SOL_Status', this.getStatusDescription), 
                    this.requestService.columnObject('SOL_DataSolicitacao', formatDate)
                ]
            },
            condition
        )
    }
}
