import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/app-core/services/user.service';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
    providers: [UserService]
})
export class AdminLayoutComponent implements OnInit {
    constructor(private userService: UserService) {}

    ngOnInit() {}

    get isCitizen(): boolean {
        return this.userService.isCidadao();
    }
}
