import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormRequestItbiComponent } from './form-request-itbi/form-request-itbi.component';
import { FormRequestSeplagComponent } from './form-request-seplag/form-request-seplag.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { GerinusToolsModule } from '../../lib/gerinus-tools.module';
import { FormRequestDefaultComponent } from './form-request-default/form-request-default.component';
import { FormRequestIptuComponent } from './form-request-iptu/form-request-iptu.component';
import { ApplicationItbiComponent } from './form-request-itbi/application-itbi/application-itbi.component';
import { FormRequestInscricaoMunicipalComponent } from './form-request-inscricao-municipal/form-request-inscricao-municipal.component';
import { RequestItbiComponent } from './form-request-itbi/request-itbi/request-itbi.component';
import { FormRequestSemurbComponent } from './form-request-semurb/form-request-semurb.component';

const components = [
  FormRequestDefaultComponent,
  FormRequestItbiComponent,
  FormRequestSeplagComponent,
  FormRequestSemurbComponent,
  FormRequestIptuComponent,
  ApplicationItbiComponent,
  RequestItbiComponent,
  FormRequestInscricaoMunicipalComponent,
  FormRequestSemurbComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ComponentsModule,
    GerinusToolsModule,
    ReactiveFormsModule,
  ]
})
export class FormsModule { }
