import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser';
import { UserOptions } from 'jspdf-autotable';
import { GlobalService } from '../lib/global.service';
import { iptuFormEnum, itbiFormEnum } from 'src/app/Enums/SpecificFormInputEnum';
import { GenerateDocumentFormService, HeaderForms, IDataTable } from './generate-document-form.service';
import { IItbiDataFormRequest } from 'src/app/interfaces/dataFormRequest';
import { IPdfData } from 'src/app/interfaces/signature-gov.interfaces';
import { getValuesActivity, getValuesGroupActivity } from '../modules/forms/form-request-semurb/utilities/values-activity';

interface IDataITBIPdf {
	subject: string;
	org: string;
	solicitante: any;
	form: IItbiDataFormRequest;
	description: string;
}

@Injectable({
	providedIn: 'root',
})
export class DocumentFormService extends GenerateDocumentFormService {

	public listBodyDefault = [
		iptuFormEnum.IPTU_ISENÇÃO_VIÚVAS,
		iptuFormEnum.IPTU_ISENÇÃO_SERVIDOR_PÚBLICO,
		iptuFormEnum.IPTU_ISENÇÃO_LOCADO_NO_MUNICÍPIO,
	]

	public listRequestITBI = [
		itbiFormEnum.ITBI_SOLICITACAO_NÃO_INCIDENCIA,
		itbiFormEnum.ITBI_SOLICITAÇÃO_DE_ISENÇÃO_DE_ITBI
	]

	constructor(
		private sanitizer: DomSanitizer,
		public global: GlobalService,
	) {
		super()
	}

	generatorDocumentFormIptuPDF(data, headerForms: HeaderForms = HeaderForms.Default): IPdfData {
		const doc = new jsPDF('p', 'mm', 'a4');
		let pdfBase64;

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);

		if (this.haveSubject(this.listBodyDefault, data.subject)) {
			this.renderBodyDefaultIptu(doc, data);
		}
		if (data.subject == iptuFormEnum.IPTU_ISENÇÃO_CEDIDO_GRATUITAMENTE) {
			this.renderBodyOrgaoCedido(doc, data);
		}
		if (data.subject == iptuFormEnum.IPTU_ISENÇÃO_SEM_FINS_LUCRATIVOS) {
			this.renderSemFinsLucrativos(doc, data);
		}
		if (data.subject == iptuFormEnum.IPTU_ISENÇÃO_IPTU_DOENÇAS_GRAVES) {
			this.renderBodyIsencaoDoenca(doc, data);
		}
		if (data.subject == iptuFormEnum.IPTU_REQUERIMENTO_DE_IPTU) {
			this.renderBodyRequerimentoIptu(doc, data);
		}
		if (data.subject == iptuFormEnum.IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU) {
			this.renderBodyRequerimentoImunidade(doc, data);
		}
		this.renderFooter(doc);

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	generatorDocumentFormITBI(data: IDataITBIPdf, headerForms: HeaderForms = HeaderForms.Default): IPdfData {
		const doc = new jsPDF('p', 'mm', 'a4');
		let pdfBase64;
		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		if (this.haveSubject(this.listRequestITBI, data.subject)) {
			this.renderBodyRequestITBI(doc, data);
		}
		if (data.subject == itbiFormEnum.ITBI_REQUERIMENTO) {
			this.renderBodyApplication(doc, data);
		}

		this.renderFooter(doc);
		pdfBase64 = doc.output('datauristring').split(',')[1];

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	renderBodyApplication(doc: jsPDF, data: IDataITBIPdf) {
		doc.setFont('Inter-SemiBold');
		console.log("[document-form.renderBodyRequestITBI] data: ", data)
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		const userOptionImovel: UserOptions = {
			theme: 'plain',
			tableLineColor: '#CCCCCC',
			headStyles: {
				lineColor: '#CCCCCC',
				lineWidth: 0.5
			},
			bodyStyles: {
				lineColor: '#CCCCCC',
				lineWidth: 0.5,
				fontSize: 8,
			},
			tableLineWidth: 0.5,
			styles: {
				fillColor: '#FFFFFF',
				cellPadding: 3,
				overflow: 'linebreak',
				fontSize: 9,
				lineColor: "#CCCCCC",
				lineWidth: 0.5,
			}
		}
		const dataTable: IDataTable[] = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(data.solicitante.SOLR_CpfCnpj)} - ${data.solicitante.SOLR_NomeRazaoSocial}` },
					{ title: "EMAIL: ", content: data.solicitante.SOLR_Email },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DO ADQUIRENTE",
				contents: [
					{ title: "ADQUIRENTE: ", content: `${this.formatCpfCnpj(data.form.SOLIT_AdquirenteCPF)} - ${data.form.SOLIT_AdquirenteNome}` },
					{ title: "ENDEREÇO: ", content: `${data.form.SOLIT_AdquirenteLogradouro}, ${data.form.SOLIT_AdquirenteNumero}, ${data.form.SOLIT_AdquirenteBairro}, ${data.form.SOLIT_AdquirenteMunicipio} - ${data.form.SOLIT_AdquirenteUF} - ${this.formatCEP(data.form.SOLIT_AdquirenteCEP)}` },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DO TRANSMITENTE",
				contents: [
					{ title: "TRANSMITENTE: ", content: `${this.formatCpfCnpj(data.form.SOLIT_TransmitenteCPF)} - ${data.form.SOLIT_TransmitenteNome}` },
					{ title: "ENDEREÇO: ", content: `${data.form.SOLIT_TransmitenteLogradouro}, ${data.form.SOLIT_TransmitenteNumero}, ${data.form.SOLIT_TransmitenteBairro}, ${data.form.SOLIT_TransmitenteMunicipio} - ${data.form.SOLIT_TransmitenteUF} - ${this.formatCEP(data.form.SOLIT_TransmitenteCEP)}` }
				],
				height: null,
				columns: 1
			},
			{
				title: "INFORMAÇÕES DA TRANSAÇÃO",
				contents: [
					{ title: "NATUREZA DA TRANSAÇÃO: ", content: data.form.SOLIT_NaturezaTransacao },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DO IMÓVEL OBJETO DE TRANSAÇÃO",
				contents: [
					{ title: "INSCRIÇÃO DO IMÓVEL: ", content: data.form.SOLIT_TransacaoInscricao },
					{ title: "ENDEREÇO: ", content: `${data.form.SOLIT_TransacaoEndereco}, ${data.form.SOLIT_TransacaoNumero}, ${data.form.SOLIT_TransacaoComplemento}` },
					{ title: "NATUREZA DA TRANSAÇÃO: ", content: `${data.form.SOLIT_NaturezaTransacao}` },
				],
				columns: 3,
				distanceTable: 0.1,
				userOptions: { ...userOptionImovel }
			},
			{
				title: "",
				contents: [
					{ title: "LOTE: ", content: data.form.SOLIT_TransacaoLote ? data.form.SOLIT_TransacaoLote : "-" },
					{ title: "QUADRA: ", content: data.form.SOLIT_TransacaoQuadra ? data.form.SOLIT_TransacaoQuadra : "-" },
					{ title: "TIPO DE IMÓVEL: ", content: data.form.SOLIT_TransacaoTipoImovel ? data.form.SOLIT_TransacaoTipoImovel : "-" },
					{ title: "ÁREA DO TERRENO: ", content: data.form.SOLIT_TransacaoAreaTerreno ? data.form.SOLIT_TransacaoAreaTerreno + "m²" : "-" },
					{ title: "FRAÇÃO IDEAL: ", content: data.form.SOLIT_TransacaoFracaoIdeal ? data.form.SOLIT_TransacaoFracaoIdeal : "-" },
				],
				columns: 5,
				distanceTable: 0.1,
				userOptions: { ...userOptionImovel }
			},
			{
				title: "",
				contents: [
					{ title: "ÁREA EDIFICADA PRIVADA: ", content: data.form.SOLIT_TransacaoAreaPrivada ? data.form.SOLIT_TransacaoAreaPrivada + "m²" : "-" },
					{ title: "ÁREA EDIFICADA TOTAL: ", content: data.form.SOLIT_TransacaoAreaTotal ? data.form.SOLIT_TransacaoAreaTotal + "m²" : "-" },
					{ title: "VALOR NÃO FINANCIADO: ", content: data.form.SOLIT_TransacaoValorNaoFinanciado ? "R$" + data.form.SOLIT_TransacaoValorNaoFinanciado : "-" },
				],
				columns: 3,
				distanceTable: 0.1,
				userOptions: { ...userOptionImovel }
			},
			{
				title: "",
				contents: [
					{ title: "VALOR FINANCIADO: ", content: data.form.SOLIT_TransacaoValorFinanciado ? "R$" + data.form.SOLIT_TransacaoValorFinanciado : "-" },
					{ title: "TOTAL DECLARADO: ", content: data.form.SOLIT_TransacaoTotalDeclarado ? "R$" + data.form.SOLIT_TransacaoTotalDeclarado : "-" },
				],
				columns: 2,
				userOptions: { ...userOptionImovel }
			},
			{
				title: "",
				contents: [
					{ title: "", content: 'Constitui crime contra a ordem tributária suprimir ou reduzir tributo mediante declaração falsa às autoridades fazendárias. Pena de reclusão de 2 (dois) a 5 (cinco) anos, e multa. (Art. 1º, I, Lei Federal 8.137/90) A base de cálculo do imposto é o valor de mercado dos imóveis objetos da transação e dos bens ou direitos transmitidos, apurados na data do efetivo recolhimento do tributo. (CTM art. 159, Lei Complementar 006/2013 de 23 de dezembro 2013).' },
				],
				height: null,
				userOptions: {
					...userOptionImovel,
					bodyStyles: {
						fontStyle: 'bold'
					}
				}
			},
			{
				title: "",
				contents: [
					{
						title: "", content: 'Declaro, para todos os fins, e sob as penas da lei, que participei, na qualidade de contribuinte ou responsável do imposto sobre transações imobiliárias por atos inter vivos, de negócio jurídico que constitui fato gerador deste tributo, estando ciente que, assumo, ainda, a total e irrestrita responsabilidade de declarar que estes atos correspondem à verdade, ficando ainda responsável por fornecer informações que servirão como base para o lançamento do ITBI e que, se a negociação não for realizada, devo informar a este fisco municipal, sob pena do crédito tributário ser inscrito na dívida ativa do município, para fins de cobrança administrativa e judicial, nos termos da legislação vigente.'
					},
				],
				height: null
			},
		]
		doc = this.generateTable(doc, dataTable);
	}

	renderBodyRequestITBI(doc: jsPDF, data: IDataITBIPdf) {
		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
		console.log("[document-form.renderBodyRequestITBI] data: ", data)
		const userOptionImovel: UserOptions = {
			theme: 'plain',
			tableLineColor: '#CCCCCC',
			headStyles: {
				lineColor: '#CCCCCC',
				lineWidth: 0.5
			},
			bodyStyles: {
				lineColor: '#CCCCCC',
				lineWidth: 0.5
			},
			tableLineWidth: 0.5,
			styles: {
				fillColor: '#FFFFFF',
				cellPadding: 3,
				overflow: 'linebreak',
				fontSize: 9,
				lineColor: "#CCCCCC",
				lineWidth: 0.5,
			}
		}

		const dataTable: IDataTable[] = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(data.solicitante.SOLR_CpfCnpj)} - ${data.solicitante.SOLR_NomeRazaoSocial}` },
					{ title: "EMAIL: ", content: data.solicitante.SOLR_Email },
					{ title: "TELEFONE: ", content: this.formatTelefone(data.form.SOLIT_SolicitanteTelefone) },
					{ title: data.form.SOLIT_SolicitanteEstadoCivil ? "ESTADO CIVIL: " : "", content: data.form.SOLIT_SolicitanteEstadoCivil ? data.form.SOLIT_SolicitanteEstadoCivil : "" },
					{ title: "ENDEREÇO: ", content: `${data.form.SOLIT_SolicitanteLogradouro}, ${data.form.SOLIT_SolicitanteNumero}${data.form.SOLIT_SolicitanteComplemento ? ', ' + data.form.SOLIT_SolicitanteComplemento : ''}, ${data.form.SOLIT_SolicitanteBairro}, ${data.form.SOLIT_SolicitanteMunicipio} - ${data.form.SOLIT_SolicitanteCEP}` }
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{
						title: "",
						content: data.description
					},
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DO IMÓVEL OBJETO DE TRANSAÇÃO",
				contents: [
					{ title: "INSCRIÇÃO DO IMÓVEL: ", content: data.form.SOLIT_Inscricao },
					{ title: "ENDEREÇO: ", content: `${data.form.SOLIT_Logradouro}, ${data.form.SOLIT_Numero},  ${data.form.SOLIT_Bairro}, ${data.form.SOLIT_Municipio} - ${data.form.SOLIT_CEP}` },
					{ title: "NATUREZA DA TRANSAÇÃO: ", content: `${data.form.SOLIT_NaturezaTransacao}` },
				],
				columns: 3,
				distanceTable: 0.1,
				userOptions: { ...userOptionImovel }
			},
			{
				title: "",
				contents: [
					{ title: "TIPO DE IMÓVEL : ", content: data.form.SOLIT_TipoImovel },
					{ title: "ÁREA DO TERRENO: ", content: data.form.SOLIT_AreaTerreno + " m²" },
					{ title: "ÁREA EDIFICADA TOTAL: ", content: data.form.SOLIT_AreaEdificadaTotal + " m²" },
					{ title: "VALOR DECLARADO: ", content: "R$ " + data.form.SOLIT_ValorDeclarado },
				],
				columns: 2,
				userOptions: { ...userOptionImovel }
			},
		];

		if (data.subject === "ITBI - SOLICITAÇÃO DE ISENÇÃO DE ITBI") {
			dataTable.push({
				title: "DESCRIÇÃO/MOTIVO: INCISO DO ART. 163",
				contents: [
					{
						title: "",
						content: (data.form.SOLIT_Descricao ? ` ${data.form.SOLIT_Descricao}` : '')
					},
				],
				height: null,
				columns: 1
			});
		}

		dataTable.push(
			{
				title: "",
				contents: [
					{ title: "", content: 'Constitui crime contra a ordem tributária suprimir ou reduzir tributo mediante declaração falsa às autoridades fazendárias. Pena de reclusão de 2 (dois) a 5 (cinco) anos, emulta. (Art. 1º, I, Lei Federal 8.137/90) A base de cálculo do imposto é o valor de mercado dos imóveis objetos da transação e dos bens ou direitos transmitidos, apurados na data do efetivo recolhimento do tributo. (CTM art. 159, Lei Complementar 006/2013 de 23 de dezembro 2013).' },
				],
				height: null,
				userOptions: {
					...userOptionImovel,
					bodyStyles: {
						fontStyle: 'bold'
					}
				}
			},
			{
				title: "",
				contents: [
					{
						title: "", content: 'Declaro, para todos os fins, e sob as penas da lei, que participei, na qualidade de contribuinte ou responsável do Imposto sobre Transações Imobiliárias por Atos Inter Vivos, de negócio jurídico que constitui fato gerador deste tributo, estando ciente que, assumo, ainda, a total e irrestrita responsabilidade de declarar que estes atos correspondem à verdade, ficando ainda responsável por fornecer informações que servirão como base para o lançamento do ITBI e que, se a negociação não for realizada, devo informar a este fisco municipal, sob pena do crédito tributário ser inscrito na dívida ativa do município, para fins de cobrança administrativa e judicial, nos termos da legislação vigente.'
					},
				],
				height: null
			}
		);

		doc = this.generateTable(doc, dataTable);
	}

	renderBodyOrgaoCedido(doc: jsPDF, data) {
		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
		const solicitante = data.form.applicantIdentification;
		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)} - ${data.solicitante.SOLR_NomeRazaoSocial}` },
					{ title: "RG: ", content: solicitante.SOLIP_RequerenteRG },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
					{ title: "ESTADO CIVIL: ", content: solicitante.SOLIP_RequerenteEstadoCivil },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null
			},
			{
				title: "DADOS DO IMÓVEL",
				contents: [
					{ title: "INSCRIÇÃO: ", content: this.formatInscricaoIPTU(data.form.propertyData.SOLIP_InscricaoImovel) },
					{ title: "ENDEREÇO: ", content: `${data.form.propertyData.SOLIP_Logradouro}, ${data.form.propertyData.SOLIP_Numero}${data.form.propertyData.SOLIP_Complemento ? ', ' + data.form.propertyData.SOLIP_Complemento : ''}, ${data.form.propertyData.SOLIP_Bairro}, ${data.form.propertyData.SOLIP_Municipio} - ${this.formatCEP(data.form.propertyData.SOLIP_CEP)}` },
				],
				height: null,
				columns: 2
			},
			{
				title: "ÓRGÃO CEDIDO",
				contents: [
					{ title: "", content: data.form.propertyData.SOLIP_Descricao },
				],
				height: null
			},
			{
				title: null,
				contents: [
					{ title: "", content: "Declaro, nesta oportunidade, estar ciente de que o reconhecimento da isenção ora requerida dependerá da documentação abaixo relacionada, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				],
				height: null
			}
		]
		doc = this.generateTable(doc, dataTable);

		return doc
	}

	public generatorDocumentFormSEPLAG(data, headerForms: HeaderForms = HeaderForms.Seplag): IPdfData {
		const doc = new jsPDF('p', 'mm', 'a4');
		doc.setFont("helvetica", "bold");

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		doc.text("Documento de Solicitação - SEPLAG", doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
		this.generateTable(doc, data);
		this.renderFooter(doc);

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	public generateDocumentFormDefault(data, headerForms: HeaderForms = HeaderForms.Default): IPdfData {
		console.log('[dataFORMPADRAO]', data);
		const doc = new jsPDF('p', 'mm', 'a4', true);
		doc.setFont("helvetica", "bold");

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		doc.text("DOCUMENTO DE SOLICITAÇÃO", doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		var tableData = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{
						title: "SOLICITANTE: ",
						content: this.formatCpfCnpj(data.solicitante.PE_CPF) + " - " + data.solicitante.PE_Nome
					}
				]
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{
						title: "ORGÃO: ",
						content: data.orgao
					},
					{
						title: "ASSUNTO: ",
						content: data.assunto
					}
				]
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null
			},
			{
				title: null,
				contents: [
					{
						title: null,
						content: "Declaro, para fins de direito, sob as penas da lei, que as informações acima prestadas e documentos são verdadeiros e autênticos. Atesto que estou ciente de que, se constatada falsidade ideológica ou documental responderei civil e criminalmente, na forma do art. 299 e seguinte Código Penal."
					}
				]
			}
		];

		if (data.applicantRequest) {
			var requerente = {
				title: "DADOS DO REQUERENTE",
				contents: [
					{
						title: "REQUERENTE: ",
						content: this.formatCpfCnpj(data.requerente.SOLR_CpfCnpj) + " - " + data.requerente.SOLR_NomeRazaoSocial
					}
				]
			};
			tableData.splice(1, 0, requerente);
		}

		this.generateTable(doc, tableData);

		this.renderFooter(doc);

		
		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	public generateDocumentFormSEMURB(data, headerForms: HeaderForms = HeaderForms.Semurb) {
		const doc = new jsPDF('p', 'mm', 'a4');
		const atividade = getValuesActivity().filter((activity) => activity.value == data.form.SOLSM_Atividade);
		const gruposAtividade = getValuesGroupActivity().filter((activityGroup) => activityGroup.value == data.form.SOLSM_GrupoAtividade);
		doc.setFont("helvetica", "bold");

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		doc.text("FORMULÁRIO DE REQUERIMENTO PADRÃO", doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		var tableData = [
			{
				title: "DADOS DO REQUERENTE",
				contents: [
					{
						title: "SOLICITANTE: ",
						content: `${this.formatCpfCnpj(data.form.SOLSM_RequerenteCpfCnpj)} - ${data.form.SOLSM_RequerenteNomeRazao}`
					},
					{
						title: "ENDEREÇO: ",
						content: `${data.form.SOLSM_RequerenteLogradouro} - ${data.form.SOLSM_RequerenteNumero} - ${data.form.SOLSM_RequerenteBairro} - ${data.form.SOLSM_RequerenteMunicipio} - ${this.formatCEP(data.form.SOLSM_RequerenteCEP)} - ${data.form.SOLSM_RequerenteComplemento}`
					},
					{
						title: "E-MAIL: ",
						content: data.form.SOLSM_RequerenteEmail
					},
					{
						title: "TELEFONE: ",
						content: this.formatTelefone(data.form.SOLSM_RequerenteTelefone)
					},
				],
				columns: 1
			},
			{
				title: "DADOS SOLICITAÇÃO",
				contents: [
					{
						title: "ORGÃO: ",
						content: data.org
					},
					{
						title: "ASSUNTO: ",
						content: data.subject
					},
				],
				columns: 1
			},
			{
				title: "DETALHES DA SOLICITAÇÃO",
				contents: [
					{
						title: "TIPO DO PROCESSO: ",
						content: data.form.SOLSM_TipoProcesso
					},
					{
						title: "GRUPO DE ATIVIDADE: ",
						content: gruposAtividade[0].value + ' - ' + gruposAtividade[0].label
					},
					{
						title: "MODALIDADE: ",
						content: data.form.SOLSM_Modalidade
					},
					{
						title: "ATIVIDADE: ",
						content: atividade[0].value + ' - ' + atividade[0].label
					},
					{
						title: "SUBTIPO DE PROCESSO: ",
						content: data.form.SOLSM_SubtipoProcesso
					},
				],
				columns: 1
			},
			{
				title: 'DESCRIÇÃO DA SOLICITAÇÃO',
				contents: [
					{
						title: '',
						content: data.description
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DA ATIVIDADE/EMPREENDIMENTO',
				contents: [
					{
						title: 'NOME DO EMPREENDIMENTO: ',
						content: data.form.SOLSM_AtividadeNomeEmpreendimento
					},
					{
						title: 'ENDEREÇO COMPLEMENTO: ',
						content: `${data.form.SOLSM_AtividadeLogradouro} - ${data.form.SOLSM_AtividadeNumero} - ${data.form.SOLSM_AtividadeBairro} - ${data.form.SOLSM_AtividadeMunicipio} - ${this.formatCEP(data.form.SOLSM_AtividadeCEP)} - ${data.form.SOLSM_AtividadeComplemento}`
					},
					{
						title: 'ÁREA TOTAL CONSTRUÍDA: ',
						content: `${data.form.SOLSM_AtividadeAreaConstruida} m²`
					},
					{
						title: 'Nº DE FUNCIONÁRIOS: ',
						content: data.form.SOLSM_AtividadeNumeroFuncionarios
					},
					{
						title: 'LICENÇA AMBIENTAL ANTERIOR: ',
						content: data.form.SOLSM_AtividadeLicencaAnterior
					},
					{
						title: 'NECESSIDADE DE SUPRESSÃO VEGETAL: ',
						content: `${data.form.SOLSM_AtividadeSupressaoVegetal} m²`
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DO CONTATO DO RESPONSÁVEL LEGAL PELO EMPREENDIMENTO',
				contents: [
					{
						title: 'RESPONSÁVEL LEGAL: ',
						content: `${this.formatCpfCnpj(data.form.SOLSM_ResponsavelCPF)} - ${data.form.SOLSM_ResponsavelNome}`
					},
					{
						title: 'ENDEREÇO: ',
						content: `${data.form.SOLSM_ResponsavelLogradouro} - ${data.form.SOLSM_ResponsavelNumero} - ${data.form.SOLSM_ResponsavelBairro} - ${data.form.SOLSM_ResponsavelMunicipio} - ${this.formatCEP(data.form.SOLSM_ResponsavelCEP)} - ${data.form.SOLSM_ResponsavelComplemento}`
					},
					{
						title: 'E-MAIL: ',
						content: data.form.SOLSM_ResponsavelEmail
					},
					{
						title: 'TELEFONE: ',
						content: this.formatTelefone(data.form.SOLSM_ResponsavelTelefone)
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DA CONSULTORIA',
				contents: [
					{
						title: 'RESPONSÁVEL LEGAL: ',
						content: `${this.formatCpfCnpj(data.form.SOLSM_ConsultoriaCpfCnpj)} - ${data.form.SOLSM_ConsultoriaNome}`
					},
					{
						title: 'E-MAIL: ',
						content: data.form.SOLSM_ConsultoriaEmail
					},
					{
						title: 'TELEFONE: ',
						content: this.formatTelefone(data.form.SOLSM_ConsultoriaTelefone)
					}
				],
				columns: 1
			},
			{
				title: '',
				contents: [
					{
						title: '',
						content: 'Declaro, para fins de direito, sob as penas da lei, que as informações acima prestadas e documentos são verdadeiros e autênticos. atesto que estou ciente de que, se constatada falsidade ideológica ou documental responderei civil e criminalmente, na forma do art. 299 e seguinte código penal.'
					}
				]
			}
		];

		this.generateTable(doc, tableData);

		this.renderFooter(doc);

		const pdfBase64 = doc.output('datauristring').split(',')[1];

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	renderSemFinsLucrativos(doc: jsPDF, data) {
		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
		const solicitante = data.form.applicantCompany
		console.log("[document-form.renderSemFinsLucrativos] data: ", data)
		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)} - ${solicitante.SOLIP_RequerenteNomeRazaoSocial}` },
					{ title: "CNAE: ", content: solicitante.SOLIP_RequerenteCNAE },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null
			},
			{
				title: "DADOS DO IMÓVEL",
				contents: [
					{ title: "INSCRIÇÃO: ", content: this.formatInscricaoIPTU(data.form.propertyData.SOLIP_InscricaoImovel) },
					{ title: "ENDEREÇO: ", content: `${data.form.propertyData.SOLIP_Logradouro}, ${data.form.propertyData.SOLIP_Numero}${data.form.propertyData.SOLIP_Complemento ? ', ' + data.form.propertyData.SOLIP_Complemento : ''}, ${data.form.propertyData.SOLIP_Bairro}, ${data.form.propertyData.SOLIP_Municipio} - ${this.formatCEP(data.form.propertyData.SOLIP_CEP)}` },
				],
				height: null,
				columns: 2
			},
			{
				title: "EXERCICIO(S) SOLICITADO(S)",
				contents: [
					{ title: "", content: data.form.propertyData.SOLIP_Descricao },
				],
				height: null
			},
			{
				title: null,
				contents: [
					{ title: "", content: "Declaro, nesta oportunidade, estar ciente de que o reconhecimento da isenção ora requerida dependerá da documentação abaixo relacionada, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				],
				height: null
			}
		]

		doc = this.generateTable(doc, dataTable);

		return doc
	}

	renderBodyDefaultIptu(doc: jsPDF, data) {
		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		const solicitante = data.form.applicantIdentification

		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)} - ${solicitante.SOLIP_RequerenteNomeRazaoSocial}` },
					{ title: "RG: ", content: solicitante.SOLIP_RequerenteRG },
					{ title: "ESTADO CIVIL: ", content: solicitante.SOLIP_RequerenteEstadoCivil },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description }
				],
				height: null
			},
			{
				title: "DADOS DO IMÓVEL",
				contents: [
					{ title: "INSCRIÇÃO: ", content: this.formatInscricaoIPTU(data.form.propertyData.SOLIP_InscricaoImovel) },
					{ title: "ENDEREÇO: ", content: `${data.form.propertyData.SOLIP_Logradouro}, ${data.form.propertyData.SOLIP_Numero}${data.form.propertyData.SOLIP_Complemento ? ', ' + data.form.propertyData.SOLIP_Complemento : ''}, ${data.form.propertyData.SOLIP_Bairro}, ${data.form.propertyData.SOLIP_Municipio} - ${this.formatCEP(data.form.propertyData.SOLIP_CEP)}` },
				],
				height: null,
				columns: 2
			},
			{
				title: null,
				contents: [
					{ title: "", content: "Declaro, nesta oportunidade, estar ciente de que o reconhecimento da isenção ora requerida dependerá da documentação relacionada, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				],
				height: null
			}
		]

		if (data.subject === iptuFormEnum.IPTU_ISENÇÃO_VIÚVAS) {
			dataTable.push({
				title: "TIPO DE ISENÇÃO",
				contents: [
					{ title: "", content: data.form.propertyData.SOLIP_Descricao }
				],
				height: null
			});
		}

		doc = this.generateTable(doc, dataTable);

		return doc;
	}

	renderBodyRequerimentoIptu(doc: jsPDF, data) {

		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		const solicitante = data.form.applicantData;
		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)} - ${solicitante.SOLIP_RequerenteNomeRazaoSocial}` },
					{ title: "ENDEREÇO: ", content: `${solicitante.SOLIP_RequerenteLogradouro}, ${solicitante.SOLIP_RequerenteNumero}${solicitante.SOLIP_RequerenteComplemento ? ', ' + solicitante.SOLIP_RequerenteComplemento : ''}, ${solicitante.SOLIP_RequerenteBairro}, ${solicitante.SOLIP_RequerenteMunicipio} - ${this.formatCEP(solicitante.SOLIP_RequerenteCEP)}` },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: 'IDENTIFICAÇÃO DA SOLICITAÇÃO',
				contents: [
					{ title: "", content: data.form.propertyData.SOLIP_Descricao },
				],
				height: null
			},
			{
				title: "DESCRIÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null
			},
			{
				title: "DADOS DO IMÓVEL",
				contents: [
					{ title: "INSCRIÇÃO: ", content: this.formatInscricaoIPTU(data.form.propertyData.SOLIP_InscricaoImovel) },
					{ title: "ENDEREÇO: ", content: `${data.form.propertyData.SOLIP_Logradouro}, ${data.form.propertyData.SOLIP_Numero}${data.form.propertyData.SOLIP_Complemento ? ', ' + data.form.propertyData.SOLIP_Complemento : ''}, ${data.form.propertyData.SOLIP_Bairro}, ${data.form.propertyData.SOLIP_Municipio} - ${this.formatCEP(data.form.propertyData.SOLIP_CEP)}` },
					{ title: "CARTOGRAFIA: ", content: data.form.propertyData.SOLIP_Cartografia },
				],
				height: null,
				columns: 1,
			},
			{
				title: '',
				contents: [
					{ title: '', content: 'Constitui crime contra a ordem tributária suprimir ou reduzir tributo mediante declaração falsa às autoridades fazendárias. Pena de reclusão de 2 (dois) a 5 (cinco) anos, e multa. (Art. 1º, I, Lei Federal 8.137/90). A base de cálculo do imposto é o valor de mercado dos imóveis objetos da transação e dos bens ou direitos transmitidos, apurados na data do efetivo recolhimento do tributo. (CTM Art. 159, Lei Complementar 006/2013 de 23 de dezembro de 2013).' }
				]
			},
			{
				title: null,
				contents: [
					{ title: "", content: "Declaro, nesta oportunidade, estar ciente de que o reconhecimento da isenção ora requerida dependerá da documentação abaixo relacionada, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				],
				height: null
			},
		]

		doc = this.generateTable(doc, dataTable);
		return doc;
	}

	renderBodyRequerimentoImunidade(doc: jsPDF, data) {
		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		const solicitante = data.form.immunityRequest;
		const immunityData = data.form.immunityData;

		let properties = {
			title: "IDENTIFICAR O(S) IMÓVEIS, NA FORMA ABAIXO: ",
			contents: [

			],
			height: null,
			columns: 2
		}

		for (let item of immunityData) {
			properties.contents.push(
				{
					title: "INSCRIÇÃO DO IMÓVEL: ",
					content: this.formatInscricaoIPTU(item.SOLII_InscricaoImovel)
				}
			);
			properties.contents.push(
				{
					title: "ENDEREÇO: ",
					content: item.SOLII_Endereco + ", " + this.formatCEP(item.SOLII_CEP)
				}
			)
		}

		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)} - ${solicitante.SOLIP_RequerenteNomeRazaoSocial}` },
					{ title: "INSCRIÇÃO DO ISS: ", content: solicitante.SOLIP_RequerenteInscricaoIss },
					{ title: "ENDEREÇO: ", content: `${solicitante.SOLIP_RequerenteLogradouro}, ${solicitante.SOLIP_RequerenteNumero}${solicitante.SOLIP_RequerenteComplemento ? ', ' + solicitante.SOLIP_RequerenteComplemento : ''}, ${solicitante.SOLIP_RequerenteBairro}, ${solicitante.SOLIP_RequerenteMunicipio} - ${this.formatCEP(solicitante.SOLIP_RequerenteCEP)}` },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
					{ title: "REPRESENTANTE LEGAL: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteRepresentanteCpf)} - ${solicitante.SOLIP_RequerenteRepresentanteNome}` },
				],
				height: null,
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				height: null,
				columns: 2
			},
			{
				title: "DESCRIÇÃO DA SOLICITAÇÃO",
				contents: [
					{ title: "", content: data.description },
				],
				height: null
			},
			{
				title: "REQUERIMENTO",
				contents: [
					{ title: "", content: "O requerente acima identificado, por intermédio de seu representante legal, vem requerer o reconhecimento da imunidade tributária, relacionada ao(s) IPTU por se tratar de instituição que possui a finalidade adiante especificada." },
				],
				height: null
			},
			{
				title: "FINALIDADE ESSENCIAL DA INSTITUIÇÃO",
				contents: [
					{ title: "", content: solicitante.SOLIP_Descricao },
				],
				height: null
			},
			properties,
			{
				title: null,
				contents: [
					{ title: "", content: "Declaro, nesta oportunidade, estar ciente de que o reconhecimento da isenção ora requerida dependerá da documentação abaixo relacionada, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				],
				height: null
			},
			{
				title: null,
				contents: [
					{ title: "", content: "Observação: Havendo deferimento do pedido e constatado posteriormente o descumprimento de quaisquer requisitos previstos na legislação, a imunidade será suspensa (art. 10, § 2º, da Lei Complementar nº 06/2013 - Código Tributário do Município)." },
				],
				height: null
			}
		]

		doc = this.generateTable(doc, dataTable);
		return doc;
	}

	renderBodyIsencaoDoenca(doc: jsPDF, data) {

		doc.setFont('Inter-SemiBold');
		doc.text(data.subject, doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		const solicitante = data.form.applicantData;
		const disease = data.form.diseaseData;
		const dataTable = [
			{
				title: "DADOS DO SOLICITANTE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(solicitante.SOLIP_RequerenteCpfCnpj)}-${solicitante.SOLIP_RequerenteNomeRazaoSocial}` },
					{ title: "RG: ", content: solicitante.SOLIP_RequerenteRG },
					{ title: "EMAIL: ", content: solicitante.SOLIP_RequerenteEmail },
					{ title: "TELEFONE: ", content: this.formatTelefone(solicitante.SOLIP_RequerenteTelefone) },
				],
				columns: 1
			},
			{
				title: "DADOS DO PORTADOR DA DOENÇA GRAVE",
				contents: [
					{ title: "IDENTIFICAÇÃO: ", content: `${this.formatCpfCnpj(disease.SOLIP_DoenteCpf)} - ${disease.SOLIP_DoenteNome}` },
					{ title: "RG: ", content: disease.SOLIP_DoenteRG },
					{ title: "VÍNCULO COM O PORTADOR DA DOENÇA: ", content: disease.SOLIP_DoenteVinculo ? disease.SOLIP_DoenteVinculo : '-' },
					{ title: "DOENÇA GRAVE/CID: ", content: disease.SOLIP_Doenca },
				],
				columns: 1
			},
			{
				title: "DADOS DA SOLICITAÇÃO",
				contents: [
					{ title: "ORGÃO: ", content: data.org },
					{ title: "ASSUNTO: ", content: data.subject },
				],
				columns: 2
			},
			{
				title: "DADOS DO IMÓVEL",
				contents: [
					{ title: "INSCRIÇÃO: ", content: this.formatInscricaoIPTU(data.form.propertyData.SOLIP_InscricaoImovel) },
					{ title: "ENDEREÇO: ", content: `${data.form.propertyData.SOLIP_Logradouro}, ${data.form.propertyData.SOLIP_Numero}${data.form.propertyData.SOLIP_Complemento ? ', ' + data.form.propertyData.SOLIP_Complemento : ''}, ${data.form.propertyData.SOLIP_Bairro}, ${data.form.propertyData.SOLIP_Municipio} - ${this.formatCEP(data.form.propertyData.SOLIP_CEP)}` },
				],
				columns: 2
			},
			{
				title: "REQUERIMENTO",
				contents: [
					{ title: "", content: "Eu, acima identificado, declaro estar ciente de que o reconhecimento da isenção ora requerida dependerá do preenchimento das condições e requisitos exigidos na legislação em vigor, bem como da apresentação da documentação relacionada no verso deste requerimento, e que a ausência de qualquer um dos referidos documentos poderá inviabilizar a análise do pedido ora formulado com o consequente arquivamento do processo." },
				]
			}
		]

		doc = this.generateTable(doc, dataTable);

		return doc;
	}

	public generatorDocumentFormInscricao(data, headerForms: HeaderForms = HeaderForms.Default): IPdfData {
		console.log('[DATA FORM INSCRICAO]', data);
		const doc = new jsPDF('p', 'mm', 'a4');
		doc.setFont('Inter-SemiBold');
		doc.text('Documento de Solicitação de Inscrição Municipal', doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		let partners = {
			title: 'SÓCIOS: ',
			contents: [],
			height: null,
			columns: 2
		};

		for (const item of data.form.partnersData) {
			partners.contents.push(
				{
					title: 'CPF/CNPJ: ',
					content: this.formatCpfCnpj(item.SIS_CpfCnpj)
				}
			)
			partners.contents.push(
				{
					title: 'NOME/RAZÃO SOCIAL: ',
					content: item.SIS_NomeRazaoSocial
				}
			)
		}

		const dataTable = [
			{
				title: 'DADOS DO SOLICITANTE',
				contents: [
					{
						title: 'SOLICITANTE: ',
						content: `${this.formatCpfCnpj(data.form.SIM_RequerenteCpfCnpj)} - ${data.form.SIM_RequerenteRazaoSocial}`
					},
					{
						title: 'E-MAIL: ',
						content: data.form.SIM_RequerenteEmail
					},
					{
						title: 'TELEFONE: ',
						content: this.formatTelefone(data.form.SIM_RequerenteTelefone)
					},
					{
						title: 'INSCRIÇÃO MUNICIPAL: ',
						content: data.form.SIM_Inscricao
					},
					{
						title: 'INÍCIO ATIVIDADE NA RFB: ',
						content: this.formatDate(data.form.SIM_AtividadeRfb)
					},
					{
						title: 'INÍCIO ATIVIDADE NO MUNICÍPIO: ',
						content: this.formatDate(data.form.SIM_AtividadeMunicipio)
					},
					{
						title: 'ENDEREÇO: ',
						content: `${data.form.SIM_RequerenteLogradouro}, ${data.form.SIM_RequerenteNumero}${data.form.SIM_RequerenteComplemento ? ', ' + data.form.SIM_RequerenteComplemento : ''}, ${data.form.SIM_RequerenteBairro}, ${data.form.SIM_RequerenteMunicipio} - ${this.formatCEP(data.form.SIM_RequerenteCEP)}`
					},
				],
				columns: 1
			},
			{
				title: 'DADOS DA SOLICITAÇÃO',
				contents: [
					{
						title: 'ORGÃO: ',
						content: data.org
					},
					{
						title: 'ASSUNTO: ',
						content: data.subject
					}
				],
				columns: 2
			},
			{
				title: 'DADOS DO IMÓVEL ONDE SERÁ LICENCIADO A ATIVIDADE',
				contents: [
					{
						title: 'INSCRIÇÃO DO IMÓVEL: ',
						content: data.form.SIM_InscricaoIptu
					},
					{
						title: 'ÁREA TOTAL UTILIZADA: ',
						content: data.form.SIM_AreaUtilizada + 'm²'
					},
					{
						title: 'ENDEREÇO: ',
						content: `${data.form.SIM_Logradouro}, ${data.form.SIM_Numero}, ${data.form.SIM_Bairro}, SÃO GONÇALO DO AMARANTE - CE - ${data.form.SIM_CEP}`
					},
				],
				columns: 1
			},
			{
				title: 'DADOS DA ATIVIDADE PRINCIPAL',
				contents: [
					{
						title: 'CÓDIGO: ',
						content: data.form.SIA_CodigoAtividade
					},
					{
						title: 'DESCRIÇÃO: ',
						content: data.form.SIA_AtividadePrincipal
					},
					{
						title: 'ESPÉCIE DO CONTRIBUINTE: ',
						content: data.form.SIM_EspecieRequerente
					},
					{
						title: 'TIPO DE TRIBUTAÇÃO: ',
						content: data.form.SIM_TipoTributacao
					}
				],
				columns: 1
			},
			{
				title: 'RESPONSÁVEL CONTÁBIL',
				contents: [
					{
						title: 'IDENTIFICAÇÃO: ',
						content: `${this.formatCpfCnpj(data.form.SIM_ResponsavelCpfCnpj)} - ${data.form.SIM_ResponsavelRazaoSocial}`
					},
					{
						title: 'CRC: ',
						content: data.form.SIM_CRC
					}
				]
			},
			partners,
			{
				title: 'RESPONSÁVEL LEGAL DA PESSOA JURÍDICA',
				contents: [
					{
						title: 'IDENTIFICAÇÃO: ',
						content: `${this.formatCpfCnpj(data.form.SIM_ResponsavelLegalCpfCnpj)} - ${data.form.SIM_ResponsavelLegalNome}`
					},
					{
						title: 'QUALIFICAÇÃO: ',
						content: data.form.SIM_ResponsavelLegalQualificacao
					}
				],
				columns: 2
			},
			{
				contents: [
					{
						title: '',
						content: 'Declaro, para fins de direito, sob as penas da lei, que as informações acima prestadas e documentos são verdadeiros e autênticos. atesto que estou ciente de que, se constatada falsidade ideológica ou documental responderei civil e criminalmente, na forma do art. 299 e seguinte código penal.'
					}
				]
			}
		];

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		this.generateTable(doc, dataTable);
		this.renderFooter(doc);

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	public generateDocumentFormSemurb(data, headerForms: HeaderForms = HeaderForms.Semurb) {
		const doc = new jsPDF('p', 'mm', 'a4');
		const atividade = getValuesActivity().filter((activity) => activity.value == data.form.SOLSM_Atividade);
		const gruposAtividade = getValuesGroupActivity().filter((activityGroup) => activityGroup.value == data.form.SOLSM_GrupoAtividade);
		doc.setFont("helvetica", "bold");

		this.renderHeader(doc, headerForms);
		this.drawHorizontalLine(38, doc);
		doc.text("FORMULÁRIO DE REQUERIMENTO PADRÃO", doc.internal.pageSize.width / 2 || doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

		var tableData = [
			{
				title: "DADOS DO REQUERENTE",
				contents: [
					{
						title: "SOLICITANTE: ",
						content: `${this.formatCpfCnpj(data.form.SOLSM_RequerenteCpfCnpj)} - ${data.form.SOLSM_RequerenteNomeRazao}`
					},
					{
						title: "ENDEREÇO: ",
						content: `${data.form.SOLSM_RequerenteLogradouro} - ${data.form.SOLSM_RequerenteNumero} - ${data.form.SOLSM_RequerenteBairro} - ${data.form.SOLSM_RequerenteMunicipio} - ${this.formatCEP(data.form.SOLSM_RequerenteCEP)} - ${data.form.SOLSM_RequerenteComplemento}`
					},
					{
						title: "E-MAIL: ",
						content: data.form.SOLSM_RequerenteEmail
					},
					{
						title: "TELEFONE: ",
						content: this.formatTelefone(data.form.SOLSM_RequerenteTelefone)
					},
				],
				columns: 1
			},
			{
				title: "DADOS SOLICITAÇÃO",
				contents: [
					{
						title: "ORGÃO: ",
						content: data.org
					},
					{
						title: "ASSUNTO: ",
						content: data.subject
					},
				],
				columns: 1
			},
			{
				title: "DETALHES DA SOLICITAÇÃO",
				contents: [
					{
						title: "TIPO DO PROCESSO: ",
						content: data.form.SOLSM_TipoProcesso
					},
					{
						title: "GRUPO DE ATIVIDADE: ",
						content: gruposAtividade[0].value + ' - ' + gruposAtividade[0].label
					},
					{
						title: "MODALIDADE: ",
						content: data.form.SOLSM_Modalidade
					},
					{
						title: "ATIVIDADE: ",
						content: atividade[0].value + ' - ' + atividade[0].label
					},
					{
						title: "SUBTIPO DE PROCESSO: ",
						content: data.form.SOLSM_SubtipoProcesso
					},
				],
				columns: 1
			},
			{
				title: 'DESCRIÇÃO DA SOLCITAÇÃO',
				contents: [
					{
						title: '',
						content: data.description
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DA ATIVIDADE/EMPREENDIMENTO',
				contents: [
					{
						title: 'NOME DO EMPREENDIMENTO: ',
						content: data.form.SOLSM_AtividadeNomeEmpreendimento
					},
					{
						title: 'ENDEREÇO COMPLEMENTO: ',
						content: `${data.form.SOLSM_AtividadeLogradouro} - ${data.form.SOLSM_AtividadeNumero} - ${data.form.SOLSM_AtividadeBairro} - ${data.form.SOLSM_AtividadeMunicipio} - ${this.formatCEP(data.form.SOLSM_AtividadeCEP)} - ${data.form.SOLSM_AtividadeComplemento}`
					},
					{
						title: 'ÁREA TOTAL CONSTRUÍDA: ',
						content: `${data.form.SOLSM_AtividadeAreaConstruida} m²`
					},
					{
						title: 'Nº DE FUNCIONÁRIOS: ',
						content: data.form.SOLSM_AtividadeNumeroFuncionarios
					},
					{
						title: 'LICENÇA AMBIENTAL ANTERIOR: ',
						content: data.form.SOLSM_AtividadeLicencaAnterior
					},
					{
						title: 'NECESSIDADE DE SUPRESSÃO VEGETAL: ',
						content: `${data.form.SOLSM_AtividadeSupressaoVegetal} m²`
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DO CONTATO DO RESPONSÁVEL LEGAL PELO EMPREENDIMENTO',
				contents: [
					{
						title: 'RESPONSÁVEL LEGAL: ',
						content: `${this.formatCpfCnpj(data.form.SOLSM_ResponsavelCPF)} - ${data.form.SOLSM_ResponsavelNome}`
					},
					{
						title: 'ENDEREÇO: ',
						content: `${data.form.SOLSM_ResponsavelLogradouro} - ${data.form.SOLSM_ResponsavelNumero} - ${data.form.SOLSM_ResponsavelBairro} - ${data.form.SOLSM_ResponsavelMunicipio} - ${this.formatCEP(data.form.SOLSM_ResponsavelCEP)} - ${data.form.SOLSM_ResponsavelComplemento}`
					},
					{
						title: 'E-MAIL: ',
						content: data.form.SOLSM_ResponsavelEmail
					},
					{
						title: 'TELEFONE: ',
						content: this.formatTelefone(data.form.SOLSM_ResponsavelTelefone)
					}
				],
				columns: 1
			},
			{
				title: 'DADOS DA CONSULTORIA',
				contents: [
					{
						title: 'RESPONSÁVEL LEGAL: ',
						content: `${this.formatCpfCnpj(data.form.SOLSM_ConsultoriaCpfCnpj)} - ${data.form.SOLSM_ConsultoriaNome}`
					},
					{
						title: 'E-MAIL: ',
						content: data.form.SOLSM_ConsultoriaEmail
					},
					{
						title: 'TELEFONE: ',
						content: this.formatTelefone(data.form.SOLSM_ConsultoriaTelefone)
					}
				],
				columns: 1
			},
			{
				title: '',
				contents: [
					{
						title: '',
						content: 'Declaro, para fins de direito, sob as penas da lei, que as informações acima prestadas e documentos são verdadeiros e autênticos. atesto que estou ciente de que, se constatada falsidade ideológica ou documental responderei civil e criminalmente, na forma do art. 299 e seguinte código penal.'
					}
				]
			}
		];

		this.generateTable(doc, tableData);

		this.renderFooter(doc);

		return {
			pdfBase64: doc.output('datauristring').split(',')[1],
			pdfBuffer: doc.output('arraybuffer'),
			pdfFileName: this.global.user().PE_Nome
		};
	}

	public formatCpfCnpj(cpfCnpj: string) {
		const isCPF = cpfCnpj.length === 11;

		return cpfCnpj.replace(
			isCPF ? /^(\d{3})(\d{3})(\d{3})(\d{2})$/ : /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
			isCPF ? '$1.$2.$3-$4' : '$1.$2.$3/$4-$5'
		);
	};

	public formatTelefone(telefone: string) {
		const digitsOnly = telefone?.replace(/\D/g, '');
		return digitsOnly?.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
	};

	public formatCEP(cep: string) {
		const digitsOnly = cep.replace(/\D/g, '');

		return digitsOnly.replace(/^(\d{5})(\d{3})$/, '$1-$2');
	};

	public formatInscricaoIPTU(inscricao: string) {
		const digitsOnly = inscricao.replace(/\D/g, '');

		return digitsOnly.replace(/^(\d{4})(\d{1})$/, '$1-$2');
	};

	public drawHorizontalLine(y, doc) {
		const lineWidth = doc.internal.pageSize.width - 20;
		const lineX = 10;
		doc.setLineWidth(0.5);
		doc.setDrawColor(0);
		doc.line(lineX, y, lineX + lineWidth, y);
	}

	public formatDate(dateString: string): string {
		if (!dateString) return dateString;

		const [year, month, day] = dateString.split('-');
		return `${day}/${month}/${year}`;
	}

}