import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProcessComponent } from '../../../pages/process/process.component';
import { ViewProcessComponent } from '../../../pages/view-processo/view-process.component';
import { MailComponent } from 'src/app/pages/view-processo/components/mail/mail.component';
import { ViewMailComponent } from 'src/app/pages/view-processo/components/view-mail/view-mail.component';
import { PageNotFoundComponent } from '../../shared/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Processos',
        },
        children: [
            {
                path: 'all',
                component: ProcessComponent,
                data: {
                    title: 'Processos',
                },
            },
            {
                path: 'sector',
                component: ProcessComponent,
                data: {
                    title: 'Processos no Setor',
                },
            },
            {
                path: 'transferred',
                component: ProcessComponent,
                data: {
                    title: 'Processos Tramitados',
                },
            },
            {
                path: 'archived',
                component: ProcessComponent,
                data: {
                    title: 'Processos Arquivados',
                },
            },
            {
                path: 'organization',
                component: ProcessComponent,
                data: {
                    title: 'Processos do Órgão',
                },
            },
            {
                path: 'view/:id',
                component: ViewProcessComponent,
                data: {
                    title: 'Visualização de Processo',
                },
            },
            {
                path: 'view/:id/mail/:type',
                component: MailComponent,
                data: {
                    title: 'Mensagens Diretas',
                },
            },
            {
                path: 'view/:id/mail/:type/:messageId',
                component: ViewMailComponent,
                data: {
                    title: 'Visualização de mensagem',
                },
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                pathMatch: 'full'
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProcessRoutingModule {}
