import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './app-core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const routesCitizen = ["/requests/view", "/profile", "/process/view", "requests/new", "/requests/edit", "/process/edit"];
    const isRouteCitizen = routesCitizen.some(route => state.url.includes(route));

    const user = localStorage.getItem('user');
    if (user) {

      if (this.userService.isCidadao() && !isRouteCitizen) this.router.navigate(['/citizen']);

      return true;
    } else {
      this.router.navigate(['/principal']);
      return false;
    }
  }
}
