import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket!: Socket;

  constructor() {
    this.socket = io(environment.apiURL, {
      path: '/socket.io',
    });
  }

  /**
   * Escutar eventos do servidor
   * @param eventName Nome do evento
   * @returns Observable dos dados recebidos
   */
  listen<T>(eventName: string): Observable<T> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: T) => {
        subscriber.next(data);
      });
    });
  }

  /**
   * Emitir eventos para o servidor
   * @param eventName Nome do evento
   * @param data Dados a serem enviados
   */
  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  /**
   * Desconectar do WebSocket
   */
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
