import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PlaceholderSignComponent } from './components/sign-gov/components/placeholder-sign/placeholder-sign.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/principal',
        pathMatch: 'full',
    },
    {
        path: 'placeholder',
        component: PlaceholderSignComponent
    },
    {
        path: '',
        loadChildren: () => import('./app-core/modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./app-core/app-core.module').then((m) => m.AppCoreModule),
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then((m) => m.AuthLayoutModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: false,
        }),
    ],
    exports: [],
})
export class AppRoutingModule {}
