import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { LoginService } from 'src/app/app-core/services/login.service';
import { NotificationService } from 'src/app/app-core/services/notification.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';

import { INotification } from 'src/app/interfaces/notification';

import swal from 'sweetalert2';
import { IUser } from 'src/app/interfaces/user';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/app-core/services/user.service';
import { WebSocketService } from 'src/app/app-core/services/web-socket.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [LoginService, NotificationService, UserService, WebSocketService],
})
export class NavbarComponent implements OnInit {
    constructor(
        public location: Location,
        public global: GlobalService,
        public login: LoginService,
        public element: ElementRef,
        public router: Router,
        public notificationService: NotificationService,
        private userService: UserService,
        public tabsetConfig: NgbTabsetConfig,
        private cryptoService: CryptoService,
        private webSocketService: WebSocketService
    ) {
        this.location = location;
        this.user = this.global.user();
        tabsetConfig.justify = 'center';
        tabsetConfig.type = 'tabs';
    }

    public listTitles: any[];
    public user: IUser;
    public notifications: any[] = [];
    public unreadNotifications: number;
    public readNotifications: number;
    public formattedGroupName: string = this.userService.formatGroupName();

    ngOnInit() {
        this.getNotifications();
        
        this.listenToNotifications();
    }

    logout() {
        if (this.global.govBr) {
            window.location.href = environment.govBR.logoutUrl;
        }
        else {
            this.login.logout();
        }
    }

    listenToNotifications() {
        const userId = this.global.user().USR_CodigoUsuario;
        
        let notificationQueue: INotification[] = [];
        let notificationTimeout: any;

        this.webSocketService.listen('new_notification').subscribe((data: INotification) => {
            if (data.NOT_Destinatario === userId) {
                notificationQueue.push(data);

                clearTimeout(notificationTimeout);
                notificationTimeout = setTimeout(() => {
                    notificationQueue.forEach(notification => {
                        this.notifications.push(this.formatNotification(notification));
                    });
                    this.playNotificationSound();
                    notificationQueue = [];
                }, 1000); 
            }
        });
    }

    playNotificationSound() {
        const audio = new Audio('assets/sounds/notification.mp3');
        audio.play().catch((error) => {
          console.error('Erro ao reproduzir o som de notificação:', error);
        });
    }

    private formatNotification(notification: any): any {
        const numberMatch = notification.NOT_Link.match(/\d+/);
    
        if (numberMatch) {
            const number = numberMatch[0];
            const encryptedNumber = this.cryptoService.encrypt(number);
            notification.NOT_Link = notification.NOT_Link.replace(number, encryptedNumber);
        }
    
        if (notification.NOT_Status === 0) {
            this.unreadNotifications++;
        } else {
            this.readNotifications++;
        }
    
        return notification;
    }

    getNotifications() {
        this.notificationService
            .listAll(
                `"NOT_Destinatario" = ${this.global.user().USR_CodigoUsuario} order by "NOT_DataNotificacao" desc`,
                0,
            )
            .subscribe({
                next: (response: any) => {
                    this.notifications = response.data;
    
                    this.unreadNotifications = 0;
                    this.readNotifications = 0;
    
                    this.notifications = this.notifications.map(this.formatNotification.bind(this));
                },
            });
    }

    async handleClickNotification(notification: INotification) {
        if (notification.NOT_Status == 0) {
            await this.handleReadNotification(notification.NOT_CodigoNotificacao);
        }

        const dropdown = document.querySelector('#dropdown-notification');
        dropdown.classList.remove('show');
    }

    async handleReadNotification(notificationId: number) {
        await this.notificationService.save({ NOT_CodigoNotificacao: notificationId, NOT_Status: 1 }).subscribe({
            next: async () => {
                this.getNotifications()
                this.unreadNotifications = this.unreadNotifications - 1;
            },
        });
    }

    async markAllNotificationsAsRead(notifications: INotification[]) {
        const unreadNotifications = notifications.filter((notification) => notification.NOT_Status === 0);

        const unreadNotificationsIds = [];

        unreadNotifications.forEach((notification) => {
            unreadNotificationsIds.push(notification.NOT_CodigoNotificacao);
        });

        this.notificationService.markAllAsRead(unreadNotificationsIds).subscribe({
            next: async () => {
                swal.fire({
                    title: 'Notificações atualizadas',
                    text: 'Todas as notificações foram marcadas como lidas',
                    icon: 'success',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#2563eb',
                });

                await this.getNotifications();
            },
        });
    }

    get isCitizen() {
        return this.userService.isCidadao();
    }
    
    get isStagingEnvironment(): boolean {
        return this.global.getEnvironment() === 'staging';
    }
}
