import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { FilingNoteService } from 'src/app/app-core/services/filing-note.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { FilingNoteEnum } from 'src/app/Enums/filingNote.enum';

@Component({
  selector: 'filing-note',
  templateUrl: './filing-note.component.html',
  styleUrls: ['./filing-note.component.scss'],
  providers: [
    FilingNoteService, 
    UserService, 
    ProcessService
  ]
})
export class FilingNoteComponent implements OnInit {

  @Input() note;
  @Input() requesterRule: boolean;
  @Input() acceptRequestRule: boolean;

  @Output() acceptOrRefuseAction = new EventEmitter();

  displayDialog = false;
  displayDialogAcceptRequest = false;
  isAccept: boolean;

  constructor(
    private filingNoteService: FilingNoteService,
    private global: GlobalService,
    private processService: ProcessService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.log("Apareceu")
  }

  requestArchive() {
    this.displayDialog = true;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  confirmArchiveRequest() {
    const id = this.note.NA_CodigoNotaArquivamento;
    const personId = this.global.user().PE_CodigoPessoa;
    this.filingNoteService.requestArchivament(id, personId).subscribe({
      next: (response: any) => {
        console.log("response: ", response)
        this.closeDialog();
        this.acceptOrRefuseAction.emit()
      }
    })
  }

  openModalConfirmRequest(accept: boolean) {
    this.displayDialogAcceptRequest = true;
    this.isAccept = accept;
  }

  closeDialogAcceptRequest() {
    this.displayDialogAcceptRequest = false;
  }

  acceptOrRefuseRequest() {
    const id = this.note.NA_CodigoNotaArquivamento;
    const status = this.isAccept? FilingNoteEnum.DESARQUIVADO : FilingNoteEnum.REJEITADO;
    const aprovadorId = this.global.user().PE_CodigoPessoa
    this.filingNoteService.acceptOrRefuseArchivament(id, status, aprovadorId).subscribe({
      next: (response: any) => {
        console.log("response: ", response)
        this.closeDialogAcceptRequest();
        this.acceptOrRefuseAction.emit()
      }
    })
  }

  public get showTextRefuse() {
    return this.note.NA_Status == FilingNoteEnum.REJEITADO
  }

  public get requestArchivamentButtonRule() {
    return this.requesterRule && this.note.NA_Status == FilingNoteEnum.ARQUIVADO;
  }

  public get acceptOrRefuseRule() {
    return this.note.NA_Status == FilingNoteEnum.SOLICITADO_DESARQUIVAMENTO;
  }
}
